import classNames from 'classnames';
import React, { BaseSyntheticEvent, useCallback, useRef, useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import styles from './Footer.module.scss';
import useTenancy from '../../hooks/useTenancy';

const SIGNUP_SOURCE = 'ecomm_site';

export default function JoinEmailForm({ placeholder }: { placeholder?: string }) {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const tenancy = useTenancy();

  const [hasFocus, setHasFocus] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(
    (e: BaseSyntheticEvent) => {
      e.preventDefault();

      if (!formRef.current || !inputRef.current) {
        return false;
      }

      const validForm = formRef.current.reportValidity();
      const email = inputRef.current.value;

      if (validForm && email.length > 0) {
        setSubmitted(true);
        setHasFocus(false);

        const payload = {
          id: email.toLowerCase(),
          email: email,
          source: SIGNUP_SOURCE,
          created_at: Math.round(new Date().getTime() / 1000),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          tenancy,
        };

        (window as any)._cio.identify(payload);
      }

      return false;
    },
    [formRef, inputRef, tenancy],
  );

  return (
    <>
      <div
        className={classNames(styles.joinEmailForm, {
          [styles.focus]: hasFocus,
        })}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <input
            className={styles.emailInput}
            type="email"
            placeholder={placeholder ?? 'Enter your email'}
            required={true}
            ref={inputRef}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
          />
          <button type="submit" onClick={handleSubmit} className={styles.submit} aria-label="Submit email">
            <ArrowIcon width={64} height={64} />
          </button>
        </form>
      </div>
      {submitted && <div className={styles.emailFormSubmitted}>Thank you for joining our list!</div>}
    </>
  );
}
