import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { useSelector } from 'react-redux';
import { AppState, Session } from '../types';
import store from '../lib/reduxStore';

export type Tenancy = 'prod' | 'test';

/**
 * @returns Hook for the data tenancy to use for this ecommerce session.
 */
export default function useTenancy(): Tenancy | undefined {
  const location = useLocation();
  const session = useSelector((state: AppState) => state.session);
  const searchParams = location.search;
  return tenancyFromSessionAndLoc(session, searchParams);
}

/**
 * @returns The current data tenancy value to use for this ecommerce session.
 */
export function getTenancy(): Tenancy | undefined {
  const session = store.getState().session;
  const searchParams = window.location.search;
  return tenancyFromSessionAndLoc(session, searchParams);
}

function tenancyFromSessionAndLoc(session: Session | null, searchParams: string) {
  if (session?.tenancy) {
    return session.tenancy;
  }
  const queryParams = queryString.parse(searchParams);
  if (queryParams.tenancy === 'test') {
    return 'test';
  }
  // ignore any other values
  return undefined;
}
