import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import Button from '../../components/Button';
import WebViewAppBar from '../../components/WebViewAppBar/WebViewAppBar';
import HideChatWidget from '../../components/ZendeskChat/HideChatWidget';
import * as events from '../../lib/analytics/events';
import { useSubscriptionCartMode } from '../../lib/cartModes';
import { subscriptionShopActions } from '../../reducers/subscriptionShop';
import styles from './PrepaidActivation.module.scss';

export default function PrepaidActivationConfirmation() {
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(cart.cartItems).length > 0) {
      dispatch(subscriptionShopActions.resetCart());
      dispatch(subscriptionShopActions.resetCheckout());
    }
  }, [cart.cartItems, dispatch]);

  const onDone = useCallback(() => {
    events.prepaidActivation.done();
    window.open('fi://closeappview');
  }, []);

  return (
    <>
      <div className={styles.confirmationContainer}>
        <WebViewAppBar title="Complete Activation" />
        <div className={styles.section}>
          <div className={styles.checkIcon}>
            <CheckIcon width="64px" height="64px" />
          </div>
          <h2>Success!</h2>
          <p>Your Fi membership is now active.</p>
        </div>
        <div className={styles.actions}>
          <Button onClick={onDone}>Done</Button>
          <div className={styles.buttonSpacer} />
        </div>
        <HideChatWidget />
      </div>
    </>
  );
}
