import classNames from 'classnames';
import React, { useState } from 'react';
import Chooser from '../../../../components/Chooser';
import USStateDropdown from '../../../../components/USStateDropdown';
import { useCartMode } from '../../../../lib/cartModes';
import * as types from '../../../../types';
import styles from './Billing.module.scss';

function StateSelectorWithRecurlyInput({ defaultValue, error }: { defaultValue?: string; error?: boolean }) {
  const [state, setState] = useState(defaultValue);
  return (
    <>
      <USStateDropdown state={state} onChange={(newState) => setState(newState)} error={error} />
      <input type="hidden" data-recurly="state" value={state} />
    </>
  );
}

function ExistingAddressForm({ shippingAddress }: { shippingAddress: types.Address }) {
  return (
    <div key="shipping-address">
      <input type="hidden" data-recurly="address1" defaultValue={shippingAddress.line1} />
      <input type="hidden" data-recurly="address2" defaultValue={shippingAddress.line2 || ''} />
      <input type="hidden" data-recurly="city" defaultValue={shippingAddress.city} />
      <input type="hidden" data-recurly="state" defaultValue={shippingAddress.state} />
      <input type="hidden" data-recurly="postal_code" defaultValue={shippingAddress.zip} />
      <input type="hidden" value="US" data-recurly="country" />
    </div>
  );
}

export interface AddressErrorFields {
  street1?: boolean;
  city?: boolean;
  state?: boolean;
  postal_code?: boolean;
}

interface NewAddressFormProps {
  compactForm?: boolean;
  existingAddress?: types.Address;
  errorFields?: AddressErrorFields;
}

function NewAddressForm({ existingAddress, compactForm, errorFields }: NewAddressFormProps) {
  return (
    <div
      key="new-billing-address"
      className={classNames(styles.newBillingAddressForm, { [styles.compact]: compactForm })}
    >
      <input
        type="text"
        name="address1"
        className={errorFields?.street1 ? styles.error : ''}
        data-recurly="address1"
        defaultValue={existingAddress?.line1}
        placeholder={compactForm ? 'Billing address' : 'Address'}
        autoComplete="street-address"
        data-hj-whitelist
      />
      <input
        type="text"
        name="address2"
        data-recurly="address2"
        defaultValue={existingAddress?.line2 || undefined}
        placeholder={'Apt/suite/other (optional)'}
        autoComplete="address-line2"
        data-hj-whitelist
      />
      <input
        type="text"
        data-recurly="city"
        name="city"
        className={errorFields?.city ? styles.error : ''}
        defaultValue={existingAddress?.city}
        placeholder={'City'}
        autoComplete="address-level2"
        data-hj-whitelist
      />
      <div className={styles.formRow}>
        <div>
          <StateSelectorWithRecurlyInput defaultValue={existingAddress?.state} error={errorFields?.state ?? false} />
        </div>
        <div>
          <input
            type="text"
            name="zip"
            data-recurly="postal_code"
            defaultValue={existingAddress?.zip}
            placeholder={'Zip Code'}
            autoComplete="postal-code"
            className={classNames('zip-code', errorFields?.postal_code ? styles.error : '')}
            data-hj-whitelist
          />
        </div>
      </div>
      <input type="hidden" value="US" data-recurly="country" />
    </div>
  );
}

interface AddressFormProps {
  shippingAddress: types.Address | undefined;
  useShippingAddress: boolean;
  billingInfo?: types.BillingInfo | null;
  compactForm?: boolean;
  errorFields?: AddressErrorFields;
}

function AddressForm({ shippingAddress, billingInfo, useShippingAddress, compactForm, errorFields }: AddressFormProps) {
  const { checkoutContext } = useCartMode();

  if (useShippingAddress && shippingAddress) {
    return <ExistingAddressForm shippingAddress={shippingAddress} />;
  }

  // The ExistingAddressForm assumes a user has recently entered a shipping address,
  // but this is not the case in the context of managing one's subscription, so here
  // we surface a NewAddressForm, which displays a full address form prepopulated
  // with existing billing information.
  if (checkoutContext === types.PaymentContext.MANAGE_SUBSCRIPTION) {
    return (
      <NewAddressForm existingAddress={billingInfo?.address} compactForm={compactForm} errorFields={errorFields} />
    );
  }
  return <NewAddressForm compactForm={compactForm} errorFields={errorFields} />;
}

interface BillingAddressProps {
  shippingAddress: types.Address | undefined;
  compactForm?: boolean;
  billingInfo?: types.BillingInfo | null;
  errorFields?: AddressErrorFields;
}

export default function BillingAddress({
  shippingAddress,
  compactForm,
  billingInfo,
  errorFields,
}: BillingAddressProps) {
  const [useShippingAddress, setUseShippingAddress] = useState(!!shippingAddress);

  return (
    <div className={styles.formSection}>
      {!compactForm && <h4>Billing address</h4>}
      {shippingAddress && (
        <Chooser
          onSelect={(useShippingAddressChoice) => setUseShippingAddress(useShippingAddressChoice)}
          selectedOption={useShippingAddress}
          groups={[
            {
              options: [
                {
                  content: 'Same as shipping address',
                  value: true,
                },
                {
                  content: 'Use a different address',
                  value: false,
                },
              ],
            },
          ]}
          showRadio
          compact
        />
      )}
      <AddressForm
        shippingAddress={shippingAddress}
        billingInfo={billingInfo}
        useShippingAddress={useShippingAddress}
        compactForm={compactForm}
        errorFields={errorFields}
      />
    </div>
  );
}
