import React, { useContext } from 'react';
import { useCartPricing } from '../../contexts/CartPricingContext';
import CheckoutContext from '../../lib/CheckoutContext';
import CartItemList from '../CartItemList';
import AccountCreditAppliedLineItem from './AccountCreditAppliedLineItem';
import CouponLineItem from './CouponLineItem';
import GiftCardAppliedLineItem from './GiftCardAppliedLineItem';
import styles from './ItemsInCart.module.scss';
import ReferralCodeLineItem from './ReferralCodeLineItem';

export interface ItemsInCartProps {
  allowRemoveCartItem?: boolean;
  allowRemoveCode?: boolean;
  allowUpdateGifting?: boolean;
}

export default function ItemsInCart({ allowRemoveCartItem, allowRemoveCode, allowUpdateGifting }: ItemsInCartProps) {
  const { cart } = useContext(CheckoutContext);
  const cartPricing = useCartPricing();

  return (
    <div className={styles.main}>
      <CartItemList allowRemoveCartItem={allowRemoveCartItem} allowUpdateGifting={allowUpdateGifting} />
      {!!cart.couponCode && (
        <CouponLineItem
          allowRemoveCoupon={allowRemoveCode}
          appliedDiscountInMonths={
            cartPricing.couponDiscount?.type === 'months' ? cartPricing.couponDiscount?.months : 0
          }
          appliedDiscountInCents={
            cartPricing.couponDiscount?.name.toLowerCase() === cart.couponCode.toLowerCase() &&
            cartPricing.couponDiscount?.type === 'dollars'
              ? cartPricing.couponDiscount?.discountAmountInCents
              : 0
          }
          couponCode={cart.couponCode}
          validationError={cartPricing.validationErrors?.couponCode?.message}
        />
      )}
      {!!cart.referralCode && (
        <ReferralCodeLineItem
          referreeFreeMonth={cartPricing.referreeEligibleForFreeMonth}
          refereeCartDiscount={cartPricing.referreeEligibleForCartDiscount}
          allowRemoveReferralCode={allowRemoveCode}
          appliedDiscountInCents={cartPricing.appliedDiscountInCents}
          validationError={cartPricing.validationErrors?.referralCode?.message}
        />
      )}
      {!!cartPricing.appliedAccountBalanceInCents && (
        <AccountCreditAppliedLineItem appliedAccountBalanceInCents={cartPricing.appliedAccountBalanceInCents} />
      )}
      {!!cart.redeemedGiftCardCode && (
        <GiftCardAppliedLineItem
          allowRemoveGiftCard={allowRemoveCode}
          redeemedGiftCardCode={cart.redeemedGiftCardCode}
          appliedGiftCardAmountInCents={cartPricing.appliedGiftCardAmountInCents}
          totalValueOfRedeemedGiftCardInCents={cartPricing.totalValueOfRedeemedGiftCardInCents}
          validationError={cartPricing.validationErrors?.redeemedGiftCardCode?.message}
        />
      )}
    </div>
  );
}
