import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCartPricing } from '../../contexts/CartPricingContext';
import Series3UpgradeContext from '../../contexts/Series3UpgradeContext';
import useProductSkuMap from '../../hooks/useProductSkuMap';
import * as events from '../../lib/analytics/events';
import { isBundleCartItem, isForModuleCartItem, isGiftCardCartItem } from '../../lib/cart';
import { useCartMode } from '../../lib/cartModes';
import CheckoutContext from '../../lib/CheckoutContext';
import { colorVariantName, sizeName } from '../../lib/product';
import { expectUnreachable, preciseSum } from '../../lib/util';
import * as types from '../../types';
import { CartItemCreditReason, isModuleSubscriptionProduct, ProductCategory, ISubscriptionProduct } from '../../types';
import styles from './CartItemList.module.scss';
import GiftCardCartItem from './GiftCardCartItem';
import ProductCartItem, { ItemPriceRowProps } from './ProductCartItem';
import useMakerBandForCartItem from './hooks/useMakerBandForCartItem';
import { SERIES_3_UPGRADE_V2_COUPON_CODE } from '../../views/Series3UpgradeV2/Series3UpgradePDPV2';
import { useFeatureValue } from '@growthbook/growthbook-react';

const ACTIVATION_FEE_LINE_ITEM_NAME = 'One time activation fee';

export interface CartItemListProps {
  allowRemoveCartItem?: boolean;
  allowUpdateGifting?: boolean;
}

function sortOrder(category: ProductCategory) {
  switch (category) {
    case types.ProductCategory.COLLAR_KIT:
      return 0;
    case types.ProductCategory.BAND:
      return 1;
    case types.ProductCategory.CHARGING_BASE:
      return 3;
    case types.ProductCategory.SUBSCRIPTION:
      return 4;
    case types.ProductCategory.ENDLINK:
      return 5;
    case types.ProductCategory.MICROCHIP:
      return 6;
    case types.ProductCategory.GIFT_CARD:
      return 7;
    case types.ProductCategory.S2_UPGRADE:
    case types.ProductCategory.MODULE:
      return 8;
    case types.ProductCategory.SUPPLEMENT:
      return 9;
    default:
      expectUnreachable(category);
      throw new Error(`Unexpected product category: ${category}`);
  }
}

function useCartItemNameTransformerForCheckoutType() {
  const { checkoutType } = useCartMode();
  const moduleIdToPetNameMap = useContext(Series3UpgradeContext)?.moduleIdToPetNameMap;

  if (checkoutType !== types.CheckoutType.Upgrade || !moduleIdToPetNameMap) {
    return (originalName: string) => originalName;
  }

  return (originalName: string, cartItem: types.CartItem) => {
    if (isForModuleCartItem(cartItem)) {
      const petName = moduleIdToPetNameMap.get(cartItem.forModuleId);
      if (petName) {
        return `${petName}'s ${originalName}`;
      }
    }

    return originalName;
  };
}

function descriptionFromCreditReason(creditReason: CartItemCreditReason, isUpgradeV2: boolean) {
  if (isUpgradeV2) {
    return 'Cyber Monday Offer';
  }

  switch (creditReason) {
    case 'upgrade':
      return 'Series 2 credit';
    case 'promo':
      return 'Discount';
    case 'household_discount_five_percent':
      return 'Multi-dog discount: 5% off';
    case 'household_discount_ten_percent':
      return 'Multi-dog discount: 10% off';
    default:
      expectUnreachable(creditReason);
      throw new Error('Unexpected credit reason');
  }
}

/**
 * Special case for subscriptions where the term length is longer than 1 billing period.
 */
function productNameWithSubscriptionCommitment(product: types.IProduct) {
  const subscriptionProductName = product.name;

  if (!isModuleSubscriptionProduct(product) || product.billingPeriodsPerTerm === 1) {
    return subscriptionProductName;
  }

  const totalTermLengthInMonths = product.billingPeriodsPerTerm * product.renewalMonths;
  if (totalTermLengthInMonths % 12 === 0) {
    const years = Math.round(totalTermLengthInMonths / 12);
    return `${subscriptionProductName} with ${years} year commitment`;
  }

  return subscriptionProductName;
}

function complexProductVariantDescription(variant: types.IVariant, bandSeries: types.BandSeries) {
  const descriptionParts = [colorVariantName(variant.options.color, bandSeries)];
  descriptionParts.push(sizeName(variant.options.size));

  return descriptionParts.join(', ');
}

function checkoutImageUrlForProduct(productId: string) {
  return `/product_images/${productId}.jpg`;
}

function checkoutImageUrlForProductWithVariant(productId: string, variant: types.IVariant) {
  return `/product_images/${productId}/${variant.options.color.replace(/[\s/]/g, '_')}.jpg`;
}

interface UsePriceRowsProps {
  bundledSubscriptionProduct?: types.ISubscriptionProduct;
  cartItemId: string;
  makerBand?: types.MakerBandDetails;
  priceInCents: number;
  productName: string;
  variantDescription?: string;
  isUpgradeV2: boolean;
}

function usePriceRows(): (props: UsePriceRowsProps) => ItemPriceRowProps[] {
  const cartPricing = useCartPricing();

  return useCallback(
    ({
      bundledSubscriptionProduct,
      cartItemId,
      makerBand,
      priceInCents,
      productName,
      variantDescription,
      isUpgradeV2,
    }) => {
      const bundledSubscriptionProductPrice =
        bundledSubscriptionProduct && !bundledSubscriptionProduct.freeTrial
          ? bundledSubscriptionProduct.priceInCents
          : 0;

      const priceRows: ItemPriceRowProps[] = [
        {
          productName,
          totalPriceInCents: preciseSum([priceInCents, bundledSubscriptionProductPrice]),
          // If this is a maker band, we show the variant descirption on a separate pricing row
          variantDescription: makerBand ? undefined : variantDescription,
        },
      ];

      if (bundledSubscriptionProduct) {
        priceRows.push({
          productName: productNameWithSubscriptionCommitment(bundledSubscriptionProduct),
          secondary: true,
          freeTrialLengthInDays: bundledSubscriptionProduct.freeTrialDays,
          // Only show a split out price for free trial subscriptions. For non-free trial subscriptions, we've
          // already bundled the subscription price into the total price in the primary row.
          totalPriceInCents: bundledSubscriptionProduct.freeTrial ? bundledSubscriptionProduct.priceInCents : undefined,
          variantDescription: bundledSubscriptionProduct?.renewalMonths === 1 ? '6 months min.' : undefined,
        });
      }

      if (makerBand) {
        priceRows.push({
          productName: makerBand.bandMakerName,
          variantDescription,
          totalPriceInCents: makerBand.bandPriceInCents,
        });
      }

      let waiveActivationFee = cartPricing.activationFeesInCents === 0;

      if (cartPricing.cartItemCredits?.[cartItemId]) {
        priceRows.push(
          ...cartPricing.cartItemCredits[cartItemId].map((credit) => {
            // Only update waiveActivationFee if it's currently false
            waiveActivationFee =
              waiveActivationFee ||
              ['household_discount_five_percent', 'household_discount_ten_percent'].includes(credit.creditReason);

            // Calculate the correct discount amount to display for the household discount credits since line item
            // discounts are bundled across potentially multiple coupons.
            const discountAmount =
              credit.creditReason === 'household_discount_five_percent'
                ? 0.05 * bundledSubscriptionProductPrice
                : credit.creditReason === 'household_discount_ten_percent'
                ? 0.1 * bundledSubscriptionProductPrice
                : credit.creditAmountInCents;
            return {
              productName: descriptionFromCreditReason(credit.creditReason, isUpgradeV2),
              totalPriceInCents: -discountAmount,
            };
          }),
        );
      }

      if (
        bundledSubscriptionProduct?.activationFeeInCents ||
        bundledSubscriptionProduct?.discountedFromOriginalActivationFeeInCents
      ) {
        priceRows.push({
          productName: ACTIVATION_FEE_LINE_ITEM_NAME,
          totalPriceInCents: waiveActivationFee ? 0 : bundledSubscriptionProduct.activationFeeInCents,
          discountedFromOriginalPriceInCents: isUpgradeV2
            ? 2000
            : waiveActivationFee
            ? bundledSubscriptionProduct.activationFeeInCents
            : bundledSubscriptionProduct.discountedFromOriginalActivationFeeInCents,
        });
      }

      return priceRows;
    },
    [cartPricing.cartItemCredits, cartPricing.activationFeesInCents],
  );
}

function useProductName(): (cartItem: types.CartItem) => string {
  const productSkuMap = useProductSkuMap();
  const maybeTransformCartItemName = useCartItemNameTransformerForCheckoutType();

  return useCallback(
    (cartItem: types.CartItem) => {
      const { product } = productSkuMap.get(cartItem.lineItem.sku) ?? {};
      if (!product) {
        throw new Error('Invalid cart item');
      }

      if (product.category === types.ProductCategory.BAND || product.category === types.ProductCategory.COLLAR_KIT) {
        return maybeTransformCartItemName(product.name, cartItem);
      }

      return productNameWithSubscriptionCommitment(product);
    },
    [maybeTransformCartItemName, productSkuMap],
  );
}

function useVariantDescription(): (cartItem: types.CartItem) => string | undefined {
  const productSkuMap = useProductSkuMap();

  return useCallback(
    (cartItem: types.CartItem) => {
      if (cartItem.lineItem.sku === 'F1-N-20') {
        return '20 chips per box';
      }

      const { product, variant } = productSkuMap.get(cartItem.lineItem.sku) ?? {};
      if (product?.category !== types.ProductCategory.BAND && product?.category !== types.ProductCategory.COLLAR_KIT) {
        return;
      }

      if (!variant) {
        throw new Error('Invalid cart item');
      }

      return complexProductVariantDescription(variant, product.bandSeries);
    },
    [productSkuMap],
  );
}

function useUpdateGiftItem(giftingFromCartEnabled: boolean) {
  const dispatch = useDispatch();
  const { checkoutActions } = useContext(CheckoutContext);

  return useCallback(
    (cartItem: types.CartItem, bundledSubscriptionProduct?: ISubscriptionProduct) => {
      if (
        !giftingFromCartEnabled ||
        !isBundleCartItem(cartItem) ||
        !cartItem.lineItem.sku.startsWith('F3') ||
        (bundledSubscriptionProduct?.renewalMonths ?? 0) < 6
      ) {
        return undefined;
      }

      return (isGift: boolean) => {
        isGift ? events.cartItemMarkedAsGift() : events.cartItemMarkedAsNotGift();
        dispatch(checkoutActions.updateGiftCartItem({ id: cartItem.cartItemId, isGift }));
      };
    },
    [dispatch, giftingFromCartEnabled, checkoutActions],
  );
}

/**
 * Group the cart items by product type for rendering.
 *
 * @returns cartItemsByGroup - an object with keys of the product type and values of React components.
 * @returns invalidCartItemIds - ids of cart items that contain invalid products.
 */
function useCartItemsByGroup(allowRemoveCartItem?: boolean, allowUpdateGifting?: boolean) {
  const dispatch = useDispatch();
  const makerBandForCartItem = useMakerBandForCartItem();
  const productSkuMap = useProductSkuMap();
  const priceRowsForCartItem = usePriceRows();
  const productNameForCartItem = useProductName();
  const variantDescriptionForCartItem = useVariantDescription();
  const { cart, checkoutActions } = useContext(CheckoutContext);
  const giftingFromCartEnabled = useFeatureValue('gifting-from-cart', false) && !!allowUpdateGifting;
  const updateGiftItemHandler = useUpdateGiftItem(giftingFromCartEnabled);

  return useMemo(() => {
    const cartItemsByGroup = new Map<ProductCategory, React.ReactNode[]>();
    const invalidCartItemIds: types.CartItemID[] = [];

    const addCartItemDetailsToGroup = (renderGroup: ProductCategory, cartItemDetails: React.ReactNode) => {
      const row = cartItemsByGroup.get(renderGroup) ?? [];
      row.push(cartItemDetails);
      cartItemsByGroup.set(renderGroup, row);
    };

    for (const cartItem of Object.values(cart.cartItems)) {
      const removeCartItem = allowRemoveCartItem
        ? () => {
            events.cartItemRemoved({ cartItem });
            dispatch(checkoutActions.removeCartItem({ id: cartItem.cartItemId }));
          }
        : undefined;

      try {
        const { product, variant } = productSkuMap.get(cartItem.lineItem.sku) ?? {};
        if (!product) {
          throw new Error('Invalid cart item');
        }

        let bundledSubscriptionProduct: ISubscriptionProduct | undefined;
        if (isBundleCartItem(cartItem)) {
          const subscriptionLineItemProduct = productSkuMap.get(cartItem.subscriptionLineItem.sku)?.product;
          if (!subscriptionLineItemProduct || !isModuleSubscriptionProduct(subscriptionLineItemProduct)) {
            throw new Error('Invalid cart item');
          }

          bundledSubscriptionProduct = subscriptionLineItemProduct;
        }

        if (product.category === types.ProductCategory.GIFT_CARD) {
          if (!isGiftCardCartItem(cartItem)) {
            throw new Error('Invalid cart item');
          }

          addCartItemDetailsToGroup(
            product.category,
            <GiftCardCartItem
              giftCardDelivery={cartItem.giftCardDelivery}
              id={cartItem.cartItemId}
              key={cartItem.cartItemId}
              productName={product.nameForBanner}
              productPriceInCents={product.priceInCents}
              removeCartItem={removeCartItem}
            />,
          );
        } else {
          const imageUrl = variant
            ? checkoutImageUrlForProductWithVariant(product.id, variant)
            : checkoutImageUrlForProduct(product.id);

          const makerBand = makerBandForCartItem(cartItem);
          const productName = productNameForCartItem(cartItem);
          const variantDescription = variantDescriptionForCartItem(cartItem);
          const priceRows = priceRowsForCartItem({
            bundledSubscriptionProduct,
            cartItemId: cartItem.cartItemId,
            makerBand,
            priceInCents: product.priceInCents,
            productName: productName,
            variantDescription,
            isUpgradeV2: cart.couponCode === SERIES_3_UPGRADE_V2_COUPON_CODE,
          });
          const updateGiftItem = updateGiftItemHandler(cartItem, bundledSubscriptionProduct);

          addCartItemDetailsToGroup(
            product.category,
            <ProductCartItem
              id={cartItem.cartItemId}
              key={cartItem.cartItemId}
              sku={cartItem.lineItem.sku}
              productImageUrl={imageUrl}
              priceRows={priceRows}
              removeCartItem={removeCartItem}
              updateGiftItem={updateGiftItem}
              isGift={isBundleCartItem(cartItem) && cartItem.isGift}
              quantity={cartItem.quantity}
            />,
          );
        }
      } catch (err) {
        invalidCartItemIds.push(cartItem.cartItemId);
      }
    }

    return { cartItemsByGroup, invalidCartItemIds };
  }, [
    allowRemoveCartItem,
    cart.cartItems,
    cart.couponCode,
    checkoutActions,
    dispatch,
    makerBandForCartItem,
    priceRowsForCartItem,
    productNameForCartItem,
    productSkuMap,
    variantDescriptionForCartItem,
    updateGiftItemHandler,
  ]);
}

export default function CartItemList({ allowRemoveCartItem, allowUpdateGifting }: CartItemListProps) {
  const dispatch = useDispatch();
  const { checkoutActions } = useContext(CheckoutContext);

  const { cartItemsByGroup, invalidCartItemIds } = useCartItemsByGroup(allowRemoveCartItem, allowUpdateGifting);

  useEffect(() => {
    if (invalidCartItemIds.length) {
      dispatch(checkoutActions.removeCartItems({ ids: invalidCartItemIds }));
    }
  }, [checkoutActions, dispatch, invalidCartItemIds]);

  const categories = useMemo(
    () => Array.from(cartItemsByGroup.keys()).sort((a, b) => sortOrder(a) - sortOrder(b)),
    [cartItemsByGroup],
  );

  return (
    <div className={styles.main}>
      {categories.map((productCategory, index) => {
        const cartItemsToRender = cartItemsByGroup.get(productCategory) ?? [];
        if (!cartItemsToRender.length) {
          return null;
        }

        return (
          <div className={styles.productGroup} key={`render-group-${index}`}>
            {cartItemsToRender}
          </div>
        );
      })}
    </div>
  );
}
