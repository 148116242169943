import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { formattedCentsToDollars } from '../../lib/util/money';

export default function usePrepaidSubscriptionOption() {
  const { device, subscriptionOptions } = usePurchaseSubscriptionContext();

  return useMemo(() => {
    const prepaidSubscription = device.prepaidSubscription;

    const devicePreselectedPlanCode = prepaidSubscription?.planCode;
    if (!devicePreselectedPlanCode) {
      return null;
    }

    const subscriptionOption = subscriptionOptions.find((option) => option.sku === devicePreselectedPlanCode);
    if (!subscriptionOption) {
      return null;
    }

    const formattedPrice = formattedCentsToDollars(subscriptionOption.priceInCents);

    const renewalPlanCode = device.prepaidSubscription?.renewalPlanCode;
    const renewalSubscriptionOption = renewalPlanCode
      ? subscriptionOptions.find((option) => option.sku === renewalPlanCode) ?? subscriptionOption
      : subscriptionOption;

    const renewalFormattedPrice = formattedCentsToDollars(renewalSubscriptionOption.priceInCents);

    const nextBillingDate = DateTime.now()
      .plus({ months: renewalSubscriptionOption.renewalMonths })
      .toFormat('MM/dd/yyyy');

    return {
      subscriptionOption,
      formattedPrice,
      renewalSubscriptionOption,
      renewalFormattedPrice,
      nextBillingDate,
      orderSource: prepaidSubscription.orderSource,
    };
  }, [device.prepaidSubscription, subscriptionOptions]);
}
