import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import { Series3UpgradeContextProvider } from '../../contexts/Series3UpgradeContext';
import Checkout from '../Checkout';
import UpgradeCart from '../Upgrade/UpgradeCart';
import Series3UpgradeV2 from '../Series3UpgradeV2/Series3UpgradeV2';
import Series3UpgradePDPV2 from '../Series3UpgradeV2/Series3UpgradePDPV2';
import { inAppCancellation } from '../../lib/analytics/events';

function withContext(Component: React.ComponentType) {
  return function WithContext() {
    return (
      <Series3UpgradeContextProvider>
        <Component />
      </Series3UpgradeContextProvider>
    );
  };
}

export default function UpgradeV2() {
  const location = useLocation();

  useEffect(() => {
    let pageName;
    const pathname = location.pathname;
    if (pathname.startsWith('/series3upgradev2/product/')) {
      pageName = 'S3 Product Selection';
    } else if (pathname.startsWith('/series3upgradev2/bag')) {
      pageName = 'Cart';
    } else if (pathname.startsWith('/series3upgradev2/checkout/shipping')) {
      pageName = 'Checkout > Shipping';
    } else if (pathname.startsWith('/series3upgradev2/checkout/payment')) {
      pageName = 'Checkout > Payment';
    } else if (pathname.startsWith('/series3upgradev2/checkout/review')) {
      pageName = 'Checkout > Review';
    } else if (pathname.startsWith('/series3upgradev2/checkout/thank-you')) {
      pageName = 'Checkout > Thank You';
    } else {
      pageName = 'Landing Page';
    }

    inAppCancellation.upgradeS2CheckoutV2Flow({}, pageName);
  }, [location]);

  return (
    <Switch>
      <SentryRoute exact path={AppPaths.Series3UpgradeV2.Root} component={withContext(Series3UpgradeV2)} />
      <SentryRoute exact path={AppPaths.Series3UpgradeV2.Bag} component={withContext(UpgradeCart)} />
      <SentryRoute path={AppPaths.Series3UpgradeV2.Checkout} component={withContext(Checkout)} />
      <SentryRoute
        exact
        path={AppPaths.Series3UpgradeV2.ProductSelector()}
        component={withContext(Series3UpgradePDPV2)}
      />
    </Switch>
  );
}
