import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../../styles/SubscriptionContainer.module.scss';
import Button from '../../../../../../components/Button';
import { AppState, gqlTypes } from '../../../../../../types';
import { usePurchaseSubscriptionContext } from '../../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import AppPaths from '../../../../../../AppPaths';
import { useHistory } from 'react-router-dom';
import { inAppCancellation as events } from '../../../../../../lib/analytics/events';
import refreshIcon from '../../../../../../assets/images/icons/icon_refresh.svg';
import { DateTime } from 'luxon';
import RenewConfirmationModal from '../../../components/RenewConfirmationModal/RenewConfirmationModal';
import { useMutation } from '@apollo/client';
import {
  pauseMembershipMutation,
  purchaseSubscriptionDeviceQuery,
  renewSubscriptionMutation,
  resumeMembershipMutation,
} from '../../../../../../graphql-operations';
import { centsToDollarsNumber, getLoadingOrErrorElement } from '../../../../../../lib/util';
import { Image } from '../../../../../../components/Image';
import Settings from '../../../../../../assets/images/settings.svg';
import Pause from '../../../../../../assets/images/pause_white_bg.svg';
import CreditCard from '../../../../../../assets/images/credit-card.svg';
import NextArrow from '../../../../../../assets/images/next.svg';
import { SubscriptionCategory } from '../../../util/types';
import { ConfirmModal } from '../../../../../../components/modals';
import Loading from '../../../../../../components/Loading';
import { onValidAppVersion, maybeTruncatePetName } from '../../../util/helpers';

interface ManageSubscriptionButtonsProps {
  canRenew: boolean;
  subscriptionCategory: SubscriptionCategory;
}

export default function ManageSubscriptionButtons({ canRenew, subscriptionCategory }: ManageSubscriptionButtonsProps) {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const session = useSelector((state: AppState) => state.session);
  const [error, setError] = useState('');
  const billingEmail = device.subscription?.billingEmail;
  const userEmail = session?.email;
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const resumeClicked = searchParams.get('resumeClicked') === 'true';
  const activePause = DateTime.now() >= DateTime.fromISO(device.subscription?.pausedAt);
  const petName = device.pet?.name ?? 'your dog';
  const shortPetName = maybeTruncatePetName(petName);
  // Check that billingEmail and userEmail are populated before checking for equality,
  // so two undefineds don't resolve to true.
  const canManageMembership = billingEmail && userEmail && billingEmail === userEmail;
  const isMonthlyPlan = subscriptionCategory === SubscriptionCategory.MONTHLY;
  const [canPause, setCanPause] = useState(
    !device.subscription?.isPaused &&
      !!device.shouldOfferPauseMonthDuringCancellation &&
      onValidAppVersion(device, window.location.search),
  );
  const [canResume, setCanResume] = useState(isMonthlyPlan && !!device.subscription?.isPaused);

  const [renewMutation, renewSubscriptionMutationState] = useMutation<
    gqlTypes.ECOMMERCE_renewSubscription,
    gqlTypes.ECOMMERCE_renewSubscriptionVariables
  >(renewSubscriptionMutation, {
    onCompleted: (data) => {
      const success = !!data.renewSubscription?.success;
      if (!success) {
        throw new Error('Failed to renew subscription: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Subscription.RenewConfirmation(device.moduleId),
        search: window.location.search,
      });
    },
  });

  const [pauseMutation, pauseMutationState] = useMutation<
    gqlTypes.ECOMMERCE_pauseMembership,
    gqlTypes.ECOMMERCE_pauseMembershipVariables
  >(pauseMembershipMutation, {
    onCompleted: (data) => {
      const success = !!data.pauseMembership?.success;
      if (!success) {
        setError(
          `Error: ${data.pauseMembership?.reasonForFailure}` ?? 'Failed to pause membership: contact customer support.',
        );
      } else {
        history.push({
          pathname: AppPaths.Subscription.PauseMembershipConfirmed(device.moduleId),
          search: window.location.search,
          state: { subscriptionCategory },
        });
      }
    },
  });

  const [resumeMutation, resumeMutationState] = useMutation<
    gqlTypes.ECOMMERCE_resumeMembership,
    gqlTypes.ECOMMERCE_resumeMembershipVariables
  >(resumeMembershipMutation, {
    refetchQueries: [{ query: purchaseSubscriptionDeviceQuery }],
    onCompleted: (data) => {
      const success = !!data.resumeMembership?.success;
      if (!success) {
        setError(
          `Error: ${data.resumeMembership?.reasonForFailure}` ??
            'Failed to resume membership: contact customer support.',
        );
      } else {
        setCanResume(false);
        setCanPause(true);
        history.push({
          pathname: AppPaths.Subscription.ResumeMembershipConfirmed(device.moduleId),
          search: window.location.search,
        });
      }
    },
  });

  if (pauseMutationState.loading || resumeMutationState.loading) {
    return <Loading />;
  }

  const hasSubscription = device.subscriptionAccessType === gqlTypes.SubscriptionAccessType.FULL_ACCESS;
  if (!hasSubscription) {
    return null;
  }

  const loadingOrErrorElement = getLoadingOrErrorElement(
    renewSubscriptionMutationState.loading,
    renewSubscriptionMutationState.error,
  );
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const handleRenew = () => {
    events.renewMembership({ petId: device.pet?.id });
    renewMutation({
      variables: {
        input: {
          moduleId: device.moduleId,
        },
      },
    });
  };

  const handleManageClick = () => {
    if (canManageMembership) {
      events.manageMembershipClicked({ petId: device.pet?.id });
      history.push({
        pathname: AppPaths.Subscription.PlanSelection(device.moduleId),
        search: window.location.search,
      });
    } else {
      const errMessage = billingEmail
        ? `This membership is associated with ${billingEmail}. To manage this membership, please login to that account.`
        : 'Unable to find billing email for this device. Please contact customer support.';
      setError(errMessage);
    }
  };

  const handlePause = () => {
    if (canPause) {
      const NUM_TERMS_TO_PAUSE = 1;
      events.claimPauseMembership({ petId: device.pet!.id, subscriptionCategory });
      pauseMutation({
        variables: {
          input: {
            recurlySubscriptionId: device.subscription!.id,
            moduleId: device.moduleId,
            numTerms: NUM_TERMS_TO_PAUSE,
          },
        },
      });
    } else {
      const errMessage = billingEmail
        ? `This membership is associated with ${billingEmail}. To manage this membership, please login to that account.`
        : 'Unable to find billing email for this device. Please contact customer support.';
      setError(errMessage);
    }
  };

  const handleResume = () => {
    if (canResume) {
      events.resumeMembership({ petId: device.pet!.id, subscriptionCategory });
      resumeMutation({
        variables: {
          input: {
            recurlySubscriptionId: device.subscription!.id,
            moduleId: device.moduleId,
          },
        },
      });
    } else {
      const errMessage = billingEmail
        ? `This membership is associated with ${billingEmail}. To manage this membership, please login to that account.`
        : 'Unable to find billing email for this device. Please contact customer support.';
      setError(errMessage);
    }
  };

  function renewButton() {
    return (
      <RenewConfirmationModal
        onContinue={() => handleRenew()}
        pageName={'Membership'}
        petId={device.pet!.id}
        trigger={
          <Button className={styles.button} secondary>
            <Image image={{ type: 'image', url: refreshIcon }} />
            <div className={styles.textContainer}>
              <p>Renew membership</p>
            </div>
            <Image image={{ type: 'image', url: NextArrow }} />
          </Button>
        }
      />
    );
  }

  function manageMembershipButton() {
    return (
      <Button className={styles.button} onClick={handleManageClick} secondary>
        <Image image={{ type: 'image', url: Settings }} />
        <div className={styles.textContainer}>
          <p>Manage membership</p>
        </div>
        <Image image={{ type: 'image', url: NextArrow }} />
      </Button>
    );
  }

  function pauseButton() {
    return (
      <ConfirmModal
        trigger={
          <Button className={styles.button} secondary>
            <Image image={{ type: 'image', url: Pause }} />
            <div className={styles.textContainer}>
              <p>Pause membership</p>
            </div>
            <Image image={{ type: 'image', url: NextArrow }} />
          </Button>
        }
        body={
          <>
            <h2>Are you sure?</h2>
            <p>
              This will pause your access to the Fi app, GPS tracking, and {device.pet?.name}'s data on{' '}
              {DateTime.fromISO(device.subscription?.currentTermEndsAt).toFormat('L/d/yy')} and skip billing for 1
              month.
            </p>
          </>
        }
        acceptText={'Confirm'}
        declineText={'No, go back'}
        onAccept={handlePause}
      />
    );
  }

  function resumeButton() {
    return (
      <ConfirmModal
        trigger={
          <Button className={styles.button} secondary>
            <Image image={{ type: 'image', url: refreshIcon }} />
            <div className={styles.textContainer}>
              <p>Resume membership</p>
            </div>
            <Image image={{ type: 'image', url: NextArrow }} />
          </Button>
        }
        body={
          <>
            <h2>Resume membership</h2>
            {activePause ? (
              <p>
                Your access to the Fi app will automatically resume and you will be billed{' '}
                {device.subscription?.subscriptionOption.priceInCents
                  ? `$${centsToDollarsNumber(device.subscription?.subscriptionOption.priceInCents)}`
                  : 'today'}
                .
              </p>
            ) : (
              <p>The scheduled pause on {shortPetName}'s membership will be canceled. You won't be billed.</p>
            )}
          </>
        }
        acceptText={'Confirm'}
        declineText={'No, go back'}
        onAccept={handleResume}
        open={resumeClicked}
      />
    );
  }

  function managePaymentButton() {
    return (
      <Button
        className={styles.button}
        secondary
        onClick={() => {
          events.updatePaymentMethodClicked({ petId: device.pet?.id });
          history.push({
            pathname: AppPaths.Subscription.UpdatePayment(device.moduleId),
            search: window.location.search,
          });
        }}
      >
        <Image image={{ type: 'image', url: CreditCard }} />
        <div className={styles.textContainer}>
          <p>Manage payment information</p>
        </div>
        <Image image={{ type: 'image', url: NextArrow }} />
      </Button>
    );
  }

  const buttonsToSurface = () => {
    if (canRenew) {
      events.subscriptionSplashButtonsLoaded({ petId: device.pet!.id }, 'Renew, Manage Payment');
      return (
        <>
          {renewButton()}
          {managePaymentButton()}
        </>
      );
    }

    if (canResume) {
      events.subscriptionSplashButtonsLoaded({ petId: device.pet!.id }, 'Resume, Manage Payment');
      return (
        <>
          {resumeButton()}
          {managePaymentButton()}
        </>
      );
    }

    if (canPause) {
      events.subscriptionSplashButtonsLoaded({ petId: device.pet!.id }, 'Manage, Pause, Manage Payment');
      return (
        <>
          {manageMembershipButton()}
          {pauseButton()}
          {managePaymentButton()}
        </>
      );
    }

    events.subscriptionSplashButtonsLoaded({ petId: device.pet!.id }, 'Manage, Manage Payment');
    return (
      <>
        {manageMembershipButton()}
        {managePaymentButton()}
      </>
    );
  };

  return (
    <>
      <div className={styles.membershipOptionsContainer}>
        {error === '' ? (
          <>
            {buttonsToSurface()}
            {canRenew && (
              <div className={styles.renewCopy}>
                <div>
                  <strong>Changed your mind?</strong>
                  <br />
                  Renew to avoid loss of app access and keep your membership active past{' '}
                  {DateTime.fromISO(device.subscription?.expiresAt).toFormat('MMMM d, y')}.
                </div>
              </div>
            )}
            {canResume && (
              <div className={styles.resumeCopy}>
                <div>
                  <strong>Changed your mind?</strong>
                  <br />
                  Resume to avoid loss of app access and keep your membership active past{' '}
                  {DateTime.fromISO(device.subscription?.currentTermEndsAt).toFormat('MMMM d, y')}.
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={styles.error}>{error}</div>
        )}
      </div>
    </>
  );
}
