import { HandsetPlatform } from '../../../../types/gql-op-types';
import { SubscriptionCategory } from './types';
import { gqlTypes } from '../../../../types';
import * as semver from 'semver';

export const maybeTruncatePetName = (name: string) => {
  const MAX_NAME_LENGTH = 16;
  if (name.length <= MAX_NAME_LENGTH) {
    return name;
  }

  return `${name.slice(0, MAX_NAME_LENGTH - 2)}..`;
};

export function getSubscriptionCategory(renewalMonths?: number) {
  // default to PREPAID if no renewal months value provided
  if (renewalMonths === undefined) {
    return SubscriptionCategory.PREPAID;
  }

  if (renewalMonths === 1) {
    return SubscriptionCategory.MONTHLY;
  } else {
    return SubscriptionCategory.PREPAID;
  }
}

// Monthly plans are eligible if they've been active for fewer than 6 months and haven't already claimed
// the offer or if they've been active for 6 months of longer. Users must also be on an app version that
// supports new blocker screen framework.
export function onValidAppVersion(device: gqlTypes.purchaseSubscriptionDeviceDetails, paramsString: string) {
  const ANDROID_MIN_VERSION = '3.56.0';
  const IOS_MIN_VERSION = '3.67.0';
  // Params search string looks like "?platform=android&app_version=3.5.41-internal"
  const searchParams = new URLSearchParams(paramsString);
  const platform = searchParams.get('platform')?.toUpperCase();
  const version = searchParams.get('app_version');

  // If we can't gather platform or version info, don't offer pause membership because we can't
  // be confident that user is on an app version that will lock them out of the app when paused.
  if (!platform || !version) {
    return false;
  }

  const onMinRequiredAppVersion =
    (platform === HandsetPlatform.IOS && semver.gte(version, IOS_MIN_VERSION)) ||
    (platform === HandsetPlatform.ANDROID && semver.gte(version, ANDROID_MIN_VERSION));
  if (!onMinRequiredAppVersion) {
    return false;
  }

  return true;
}
