/*
 * Common product definitions for ecommerce.
 *
 * IMPORTANT NOTE: This file should be edited in the `web` repo only! Edit in
 * `web` and then run `yarn product:types` in the `ecommerce` repository to sync types.
 */

export const series1CollarId = 'smart-collar';
export const series2CollarId = 'smart-collar-v2';
export const series3CollarId = 'smart-collar-v3';

export const collarProductIds = [series1CollarId, series2CollarId, series3CollarId] as const;
export type CollarProductId = typeof collarProductIds[number];

export const f1BandId = 'additional-band';
export const f3BandId = 'additional-band-s3';
export const martingaleBandId = 'martingale-band';

export const f3EndlinkId = 'endlinks-f3';
export const f3EndlinkSmallId = 'endlinks-f3-small';

/**
 * sku prefix for a Series 3 collar kit with a maker's band
 */
export const f3MakerKitSkuBase = 'F3-MBKUA';
export const f3MakerBandSkuBase = 'F3-K';

/**
 * sku for a Series 3 collar kit box that contains only a module and charging base (no band)
 */
export const series3BaseModuleKitSku = 'F3-MBUA';

/**
 * The `subItemCodes` property on products are used by the returns tracker to help determine what individual components
 * are part of the product when making a warranty exchange.
 *
 * M = module
 * B = charging base
 * C = fi band
 * K = makers band
 * U = usb cord
 * A = a/c adapter
 *
 * A Fi collar kit would have subitem codes M, B, C, U, and A. Using this information, when creating an RMA (return
 * merchandise authorization) a CX (customer experience) agent might want to only exchange one or more of these
 * individual components depending on the problem the customer is having e.g. if the band doesn't fit, but the module
 * and charing base work fine, then we would rather just replace the band ('C' subitem) instead of replacing the entire
 * collar kit.
 *
 * TODO: Convert these to enums (https://app.asana.com/0/1202414697517517/1205187196777579/f)
 */
export type ReturnsTrackerBandSubItemCode = 'C' | 'K';
export type ReturnsTrackerSubItemCode = ReturnsTrackerBandSubItemCode | 'M' | 'B' | 'U' | 'A';

/**
 * The billing cadence for a subscription. This is used to distinguish between yearly and monthly
 * pricing schemes. This is different from the billing *period*: a 2 year plan has a billing period
 * of 2 years but a cadence of Year.
 */
export enum BillingCadence {
  Month = 'month',
  Year = 'year',
  Week = 'week',
}

/**
 * The tender determines how the user paid / will pay. Currently it's only used to flag certain tenders as invalid for use on products.
 *
 * Aliased to the corresponding GQL type for consistency.
 */

export enum TenderType {
  CreditCard = 'CreditCard',
  PayPal = 'PayPal',
}

export type ProductID = string;
export type IProduct =
  | IBandProduct
  | IChargingBaseProduct
  | ICollarKitProduct
  | IEndlinkProduct
  | IGiftCardProduct
  | IModuleProduct
  | INanoProduct
  | IS2UpgradeProduct
  | IS2UpgradeInsertProduct
  | ISubscriptionProduct
  | ISupplementSubscriptionProduct
  | ISupplementProduct;

export enum ProductLabel {
  Series2KitOrModule = 'Series2KitOrModule',
  ReturnRefundEligible = 'ReturnEligible',
  /**
   * A discontinued product may no longer be RMA'd by itself, for example a series 1 kit.
   */
  Discontinued = 'Discontinued',
  /**
   * A product that's no longer manufactured, so we wouldn't be able to fulfill an order for it.
   */
  NoLongerManufactured = 'NoLongerManufactured',
  /**
   * A product that has a serial number e.g. FB11K44001, FC21K79010, FN1201H47562
   */
  Serialized = 'Serialized',
  /**
   * A product that cannot be added to an RMA for return or replacement
   */
  IneligibleForRma = 'IneligibleForRma',
  /**
   * By default, customers are instructed to dispose of their product on a warranty exchange. This label
   * indicates the product should be flagged for returning to us instead.
   */
  ReturnProductOnExchange = 'ReturnProductOnExchange',
}

interface IBaseProduct {
  id: ProductID;
  name: string;
  priceInCents: number;
  description: string;
  /**
   * Whether this product is shown as the front-page item.
   */
  featured?: boolean;
  /**
   * If this is a string, it defines a coupon name prefix for coupons that
   * may be applied to this product.
   */
  couponEligible?: boolean | string[];
  exchangeOnly?: boolean;

  /**
   * @deprecated Use `physical` instead.
   */
  physical: boolean;

  unavailableForTenders?: Array<TenderType>;
  labels?: ProductLabel[];
  subItemCodes?: ReturnsTrackerSubItemCode[];
  discountedFromOriginalPriceInCents?: number;
  category: ProductCategory;
}

export enum ProductCategory {
  COLLAR_KIT,
  MODULE,
  BAND,
  CHARGING_BASE,
  GIFT_CARD,
  SUBSCRIPTION,
  ENDLINK,
  MICROCHIP,
  S2_UPGRADE,
  SUPPLEMENT,
}

interface ISimpleProduct extends IBaseProduct {
  sku: string;
  weightInOunces?: number;
  /**
   * This attribute is used to determine the cost of goods to split shipments when they reach a max value
   */
  factoryCostInDollars?: number;
}

export interface IEndlinkProduct extends ISimpleProduct {
  bandSeries: BandSeries;
  size?: string;
  physical: true;
  category: ProductCategory.ENDLINK;
}

export interface IChargingBaseProduct extends ISimpleProduct {
  physical: true;
  category: ProductCategory.CHARGING_BASE;
}

export interface INanoProduct extends ISimpleProduct {
  physical: true;
  category: ProductCategory.MICROCHIP;
}

export interface IModuleProduct extends ISimpleProduct {
  physical: true;
  category: ProductCategory.MODULE;
}

export interface IS2UpgradeInsertProduct extends ISimpleProduct {
  physical: true;
  category: ProductCategory.S2_UPGRADE;
}

export interface IS2UpgradeProduct extends ISimpleProduct {
  physical: false;
  category: ProductCategory.S2_UPGRADE;
}

/**
 * Fi makes our own bands for collars, but third-party makers are also able to make bands using Fi endlinks.
 */
export enum BandMaker {
  Fi = 'Fi',
  // Third-party makers
  LandsharkSupply = 'Landshark Supply',
  MimiGreen = 'Mimi Green',
  RopeHounds = 'Rope Hounds',
  StuntPuppy = 'Stunt Puppy',
  ZeeDog = 'Zee.Dog',
}

/**
 * A product could have many "variants" representing skus for all of the individual options the customer can choose
 * from when purchasing that product. For example, a collar kit product will have a variant for each color/size
 * combination that is available for that product.
 */
interface IBaseVariant {
  sku: string;
  options: IVariantOptions;
  weightInOunces?: number;
  factoryCostInDollars?: number;
  /**
   * Some variants require an upcharge on top of the base price of the product (e.g. makers band). This attribute will
   * hold the amount of that upcharge in cents.
   */
  additionalPriceInCents?: number;
  bandMaker: BandMaker;
  bandSubItemCode: ReturnsTrackerBandSubItemCode;
  /**
   * We will always want to keep all variants that we've sold sold in the product configuration for order history
   * purposes, but we will want to hide discontinued variants from UIs so they should be flagged accordingly.
   */
  discontinued?: boolean;
}

export type IBandVariant = IBaseVariant;

export interface ICollarKitVariant extends IBaseVariant {
  /**
   * While the `sku` attribute of a collar kit variant represents the sku for the full kit (e.g. F3-MBCUA-GY-S), the
   * `bandSku` is meant to represent the sku for only the spare band that is contained within that kit (e.g. F3-C-GY-S)
   */
  bandSku: string;
}

export type IVariant = IBandVariant | ICollarKitVariant;

export interface ICollarKitProduct extends IBaseProduct {
  id: CollarProductId;
  /**
   * If true, the user MUST include a bundled subscription with this product to checkout. The product cannot be
   * purchased otherwise.
   */
  bundledSubscriptionRequiredForPurchase: boolean;
  physical: true;
  category: ProductCategory.COLLAR_KIT;
  bandSeries: BandSeries;
  variants: ICollarKitVariant[];
  /**
   * Some of our collar kits will be kitted at the 3PL. We will send them the full collar kit sku and they break it
   * down into a sku for a kit with no band (the box includes only a module + charging base) and a sku for only the
   * spare band. They add the band to the box to put together the full kit at time of shipping. We will need to know
   * the sku for the kit without the band so we can check inventory accordingly.
   */
  baseModuleKitSku?: string;
}

export interface ICollarJITKitProduct extends ICollarKitProduct {
  baseModuleKitSku: string;
}

export interface IBandProduct extends IBaseProduct {
  id: ProductID;
  physical: true;
  category: ProductCategory.BAND;
  bandSeries: BandSeries;
  variants: IBandVariant[];
}

export type IProductWithVariants = ICollarKitProduct | IBandProduct;
export type IProductWithoutVariants = Exclude<IProduct, IProductWithVariants>;
export type IPhysicalProduct = Exclude<IProduct, IGiftCardProduct | ISubscriptionProduct | IS2UpgradeProduct>;

export interface IGiftCardProduct extends IBaseProduct {
  sku: string;
  physical: false;
  /**
   * The name that will be used in the yellow banner that's displayed at the top
   * of the screen during the redemption code. Right now this just omits "(Gift)"
   * from the end since that looks weird.
   */
  nameForBanner: string;
  /**
   * When trying to find gift card details by looking them up by their dollar
   * amount, also consider these alternative prices to be instances of this gift card.
   */
  alternativePricesInCents?: number[];
  category: ProductCategory.GIFT_CARD;
}

export interface ISupplementSubscriptionProduct extends IBaseProduct {
  sku: string;
  /**
   * This is the sku of the product that will be shipped to the customer when they purchase this supplement.
   * It may differ from the SKU on the order line item which is more directly associated with the subscription cadence
   */
  skuToShip: string;
  physical: true;
  category: ProductCategory.SUPPLEMENT;
  weightInOunces: number;
  factoryCostInDollars?: number;
  activationFeeInCents?: number;
  freeTrial: boolean;
  freeTrialDays?: number;
  daysBetweenShipments: number;
  weeksBetweenShipments: number;
  billingCadence: BillingCadence;
  displayedRate: string;
  displayedRateUnit: string;
  /**
   * How many billing periods are in a term? A term is the default length of time that customers are committed to a
   * subscription. e.g. a subscription with renewalMonths=1, billingPeriodsPerTerm=6 is a subscription that bills
   * once every month, and there are 6 months in a term. So the customer is committed to the subscription for 6 months.
   * e.g. a subscription with renewalMonths=12, billingPeriodsPerTerm=1 is a subscription that bills once every year
   * and there is 1 year in a term. So the customer is committed to the subscription for 1 year.
   */
  billingPeriodsPerTerm: number;
  recommendedForDogSize: SizeOption;
  recommendedForDogWeightRangePounds: WeightRange;
  // Used to display new discounted price vs old price
  originalPriceInCents?: number;
  // The product name used in customer.io event tracking, this allows for more detail than we show on ecommerce site
  customerIoName: string;
  // The product name used in the in-app subscriptions management flow
  inAppManagementName: string;
  // If this plan should be displayed in ecomm. This is false for plans without free trials.
  display: boolean;
  // Plans without free trials replace plans without free trials when a user is purchasing any supplement subscription except their first.
  replacedBySku?: string;
  savings?: string;
}

export interface ISupplementSubscriptionProduct extends IBaseProduct {
  sku: string;
  /**
   * This is the sku of the product that will be shipped to the customer when they purchase this supplement.
   * It may differ from the SKU on the order line item which is more directly associated with the subscription cadence
   */
  skuToShip: string;
  physical: true;
  category: ProductCategory.SUPPLEMENT;
  weightInOunces: number;
  factoryCostInDollars?: number;
  activationFeeInCents?: number;
  freeTrial: boolean;
  freeTrialDays?: number;
  daysBetweenShipments: number;
  weeksBetweenShipments: number;
  billingCadence: BillingCadence;
  displayedRate: string;
  displayedRateUnit: string;
  /**
   * How many billing periods are in a term? A term is the default length of time that customers are committed to a
   * subscription. e.g. a subscription with renewalMonths=1, billingPeriodsPerTerm=6 is a subscription that bills
   * once every month, and there are 6 months in a term. So the customer is committed to the subscription for 6 months.
   * e.g. a subscription with renewalMonths=12, billingPeriodsPerTerm=1 is a subscription that bills once every year
   * and there is 1 year in a term. So the customer is committed to the subscription for 1 year.
   */
  billingPeriodsPerTerm: number;
  recommendedForDogSize: SizeOption;
  recommendedForDogWeightRangePounds: WeightRange;
  // Used to display new discounted price vs old price
  originalPriceInCents?: number;
  // The product name used in customer.io event tracking, this allows for more detail than we show on ecommerce site
  customerIoName: string;
  // The product name used in the in-app subscriptions management flow
  inAppManagementName: string;
  // If this plan should be displayed in ecomm. This is false for plans without free trials.
  display: boolean;
  // Plans without free trials replace plans without free trials when a user is purchasing any supplement subscription except their first.
  replacedBySku?: string;
}

export interface ISupplementProduct extends IBaseProduct {
  sku: string;
  skuToShip: string;
  physical: true;
  category: ProductCategory.SUPPLEMENT;
  weightInOunces: number;
  factoryCostInDollars?: number;
  activationFeeInCents?: number;
  freeTrial: boolean;
  freeTrialDays?: number;
  daysBetweenShipments: number;
  weeksBetweenShipments: number;
  // Used to display new discounted price vs old price
  originalPriceInCents?: number;
  // The product name used in customer.io event tracking, this allows for more detail than we show on ecommerce site
  customerIoName: string;
  // The product name used in the in-app subscriptions management flow
  inAppManagementName: string;
  // If this plan should be displayed in ecomm. This is false for plans without free trials.
  display: boolean;
}

export interface ISubscriptionProduct extends IBaseProduct {
  sku: string;
  physical: false;
  displayedRate: string;
  displayedRateUnit: string;
  mostPopular?: boolean;
  freeTrial?: boolean;
  /**
   * A string depiction of the savings to be displayed, like "Save 16%"
   */
  savings?: string;
  /**
   * The amount users would save if they upgrade from a monthly subscription. Used in the monthly upgrade flow.
   */
  monthlyUpgradeSavings?: string;
  /**
   * Renews after *n* years.
   *
   * @deprecated Use `renewalMonths` instead.
   */
  renewalYears?: number;
  /**
   * Renews after *n* months. How long is a single billing period?
   */
  renewalMonths: number;
  /**
   * "Buy It" membership plans will be configured in Recurly with a billing period of 5 years and set to expire at the
   * end of that initial 5 year term instead of auto-rewnewing so we won't automatically charge them again. Then, if
   * the customer is still using the device after those 5 years and contacts support after seeing their subscription
   * has expired, a cx agent can manually turn the subscription back on for no charge. We will, therefore, be setting
   * renewalMonths to 60 for these bought plans in the backend product config which helps this type of plan to play
   * nicely with our existing grace period logic.
   *
   * Since we will continue to set a renewalMonths value on "buy it" plans, this buyItMembership flag will help us
   * distinguish between "buy it" plans and long-term prepaid plans where we need to (e.g. frontend clients)
   */
  buyItMembership?: boolean;
  /**
   * How many billing periods are in a term? A term is the default length of time that customers are committed to a
   * subscription. e.g. a subscription with renewalMonths=1, billingPeriodsPerTerm=6 is a subscription that bills
   * once every month, and there are 6 months in a term. So the customer is committed to the subscription for 6 months.
   * e.g. a subscription with renewalMonths=12, billingPeriodsPerTerm=1 is a subscription that bills once every year
   * and there is 1 year in a term. So the customer is committed to the subscription for 1 year.
   */
  billingPeriodsPerTerm: number;
  /**
   * Free trial for *n* days.
   */
  freeTrialDays?: number;
  billingCadence: BillingCadence;
  fullRate?: string;
  fullRateUnit?: string;

  activationFeeInCents?: number;

  /**
   * In case we want to waive/discount the activation fee (e.g. A/B testing), activationFeeInCents will hold
   * the actual amount we want to charge. discountedFromOriginalActivationFeeInCents will define what the original fee
   * for frontend display purposes.
   */
  discountedFromOriginalActivationFeeInCents?: number;

  /**
   * Product IDs of collars that can use this subscription
   */
  canBeActivatedOnProductIds: CollarProductId[];
  /**
   * Product IDs of collars for which this subscription can be bundled with. Exmaple of how this differs from
   * canBeActivatedOnProductIds: when purchasing an S3 upgrade, customers will be buying a subscription that
   * can only be _purchased_ with S3 collars.  However, that subscription will immediately be _activated_ on their S2
   * collar. i.e. canBePurchasedForProductIds = [s3], canBeActivatedOnProductIds = [s2, s3]
   */
  canBePurchasedForProductIds: string[];
  category: ProductCategory.SUBSCRIPTION;

  /**
   * We currently have some plans that aren't selectable in the list of subscription options, but we may try
   * to upsell them to the customer through an interstitial modal if they select a shorter term plan. This upsellOnly
   * flag will be used for that purpose. The plan will still need to include with the ecommerce config but the frontend
   * will use this to know to hide it from the list on the product page.
   */
  upsellOnly?: boolean;

  /**
   * If the user selects this subscription product and attemptUpsellToPlanCode is defined, the frontend should
   * attempt to upsell the user to this plan code instead.
   */
  attemptUpsellToPlanCode?: string;
  /**
   * Some membership options have additional labels added to them on the PDP
   * that indicate some kind of value proposition to users
   */
  valueLabel?: string;

  /**
   * Some membership options come with access to phone support, dubbed "Support+"
   */
  supportPlus?: boolean;

  /*
   * Only certain subscriptions are eligible for a referree free month
   */
  eligibleForRefereeFreeMonth?: boolean;

  /**
   * Flag to determine if this plan is currently only available for A/B tests
   */
  experimentOnly?: boolean;

  /**
   * Specifies which experiment is required for this plan to be available
   */

  /**
   * Lists which experiments should exclude this product when user is enrolled in them
   */

  /**
   * Flag to determine if this plan is eligibile for extension by use of coupon
   */
  eligibleForCouponExtension?: boolean;

  /**
   * The number of  months that internal coupon code can extend the subscription by
   */
  couponExtensionMonths?: number;

  /**
   * If we were to switch to this plan now, this is the amount of credit that would be applied to the switch
   * based on the refund policy of the original plan. Usually this is a prorated amount.
   */
  planSwitchCreditAppliedInCents?: number;

  /**
   * If we were to switch to this plan now, this is how much the user would actually pay today.
   */
  planSwitchAmountChargedInCents?: number;
}

export enum ColorOption {
  Yellow = 'yellow',
  YellowScribble = 'yellow scribble',
  Gray = 'gray',
  GrayScribble = 'gray scribble',
  Blue = 'blue',
  BlueScribble = 'blue scribble',
  Pink = 'pink',
  PinkScribble = 'pink scribble',
  Strava = 'strava',

  // Makers band styles
  LandsharkSupplyBlackBlue = 'Landshark Supply Black/Blue',
  LandsharkSupplyBlackRed = 'Landshark Supply Black/Red',
  MimiGreenPinkReflective = 'Mimi Green Pink Reflective',
  MimiGreenRedBiothane = 'Mimi Green Red Biothane',
  RopeHoundsBubblegumPop = 'Rope Hounds Bubblegum Pop',
  RopeHoundsMountainTop = 'Rope Hounds Mountain Top',
  StuntPuppyFishtailTeal = 'Stunt Puppy Fishtail Teal',
  StuntPuppyFloraFrolic = 'Stunt Puppy Flora Frolic',
  StuntPuppyGoDog = 'Stunt Puppy Go Dog',
  StuntPuppyPinesBlue = 'Stunt Puppy Pines Blue',
  StuntPuppyTealBiothane = 'Stunt Puppy Teal Biothane',
  ZeeDogPhantom = 'ZeeDog Phantom',
}

export enum SizeOption {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

// Providing one but not the other indicates unbounded in that direction (eg. {max: 20} means 20 lbs or less)
interface WeightRange {
  min?: number;
  max?: number;
}

export enum BandSeries {
  // The F1 band is compatible with Series 1 and Series 2 collars
  F1 = 'F1',
  // The F3 band is compatible with Series 3 collars
  F3 = 'F3',
}

export enum CollarTypeOption {
  Standard = 'standard',
  Martingale = 'martingale',
}

export interface IVariantOptions {
  color: ColorOption;
  size: SizeOption;
  collarType: CollarTypeOption;
}

export interface IBaseEcommerceVariantConfig {
  variant: string;
  /** Event to fire on page load */
  event: string;
}

export interface IECommerceSiteConfig {
  // Entries here should be optional with the frontend choosing a sane default, so
  // that we don't have to worry about versioning this between frontend/backend.
  showCoupons?: boolean;
  showApplePay?: boolean;
  enableGiftCards?: boolean;
  refereeRewardProductId?: string;
  blockedOutOfStockSkus?: string[];
  allowPaypalCredit?: boolean;
  isReseller?: boolean;
  experiments?: {
    blank?: IBaseEcommerceVariantConfig;
    makerkitsexperiment?: IBaseEcommerceVariantConfig;
    s3pricetest1?: IBaseEcommerceVariantConfig;
    s3pricetest2a?: IBaseEcommerceVariantConfig;
    s3pricetest3?: IBaseEcommerceVariantConfig;
    s3pricetest4?: IBaseEcommerceVariantConfig;
    s3pricetest7?: IBaseEcommerceVariantConfig;
    s3pricetest1a?: IBaseEcommerceVariantConfig;
  };
  shouldResetAnonymousId?: boolean;
  addressValidationEnabled?: boolean;
  showThankYouPageSurvey?: boolean;
  referreeFreeMonth?: boolean;
  multiDogDiscountAmount?: number;
  multiDogHouseholdDiscountEnabled?: boolean;
  showInAppCancellationChatButton?: boolean;
  enableEcommPromoBannerImprovements?: boolean;
  cancelNewShipments?: boolean;
  showDiscountDeterrent?: boolean;
  showMetalCollarCircleImages?: boolean;
}

export type ExperimentId = keyof Required<IECommerceSiteConfig>['experiments'];

export type ShippingCode = 'so-free' | 'so-2day' | 'so-overnight' | 'so-priority_mail' | 'so-in_person';

export interface IShippingOption {
  code: ShippingCode;
  name: string;
  displayedCarrier?: string;
  /**
   * Used as a subtitle in Apple Pay.
   *
   * E.g. "Arrives in 5 to 7 days".
   */
  detail: string;
  priceInCents: number;
  /**
   * Whether the shipping option is an expedited shipping option that needs to be refunded
   * in the event of a backorder situation.
   */
  isExpedited: boolean;
  exchangeOnly?: boolean;
  inPersonEventOnly?: boolean;
  /**
   * An ISO date. If specified, we should display text that "the package will arrive on <day>"
   * in the checkout flow.
   *
   * This is dynamically calculated.
   */
  arrivesOn?: string;
}

// Calculated by subtracting the spare band weight from the kit weight in:
// https://docs.google.com/spreadsheets/d/1IQozPMbeg-4lVSStsjV3AoLG1HggkvQcMDe5CJ9J_XU/edit#gid=158751532
export const WEIGHT_IN_OUNCES_OF_S3_KIT_WITHOUT_BAND = 11.0053;

// cost from https://docs.google.com/spreadsheets/d/1QxsqGN-Xwj5Uil40wauPvFLs-6Jh0ovnoEjllujIyd0/edit#gid=0
export const FACTORY_COST_IN_DOLLARS_OF_S3_KIT_WITHOUT_BAND = 78.48;

export interface EcommerceApiErrorExtensions {
  customerMessage?: string;
}

export const giftCardCookieName = 'gift_card_redemption_code';
export const fakeRefereeRewardForPricingCouponCode = 'fake_referee_reward_for_pricing';

export function isModuleSubscriptionProduct(product: IProduct): product is ISubscriptionProduct {
  return product.category === ProductCategory.SUBSCRIPTION;
}

export function isSupplementSubscriptionProduct(product: IProduct): product is ISupplementSubscriptionProduct {
  return product.category === ProductCategory.SUPPLEMENT && !isSinglePurchaseSupplementProduct(product);
}

export function isSinglePurchaseSupplementProduct(product: IProduct): product is ISupplementSubscriptionProduct {
  return product.category === ProductCategory.SUPPLEMENT && product.weeksBetweenShipments === 0;
}

export function isCollarKitProduct(product: IProduct): product is ICollarKitProduct {
  return product.category === ProductCategory.COLLAR_KIT;
}

export function isBundledSubscriptionRequiredForPurchase(product: IProduct): boolean {
  return product.category === ProductCategory.COLLAR_KIT && product.bundledSubscriptionRequiredForPurchase;
}

/**
 * Just-in-time (JIT) kitting is when the fulfillment center will put the kit together at time of shipping. In the
 * context of Fi's business, this means putting the size/color band ordered into a pre-assembled box with the module
 * and charging base.
 */
export function isEligibleForJITKitting(product: IProduct): product is ICollarJITKitProduct {
  return isCollarKitProduct(product) && !!product.baseModuleKitSku;
}
