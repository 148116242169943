import React from 'react';
import SubscriptionAppBar from '../../../../components/SubscriptionAppBar/SubscriptionAppBar';
import PurchaseSubscriptionPaymentBody from '../../../../../PurchaseSubscription/PurchaseSubscriptionPayment/PurchaseSubscriptionPaymentBody';
import { usePurchaseSubscriptionContext } from '../../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { inAppCancellation as events } from '../../../../../../lib/analytics/events';
import styles from './UpdatePayment.module.scss';
import AppPaths from '../../../../../../AppPaths';
import { useHistory } from 'react-router-dom';

export default function UpdatePayment() {
  const { device } = usePurchaseSubscriptionContext();
  const history = useHistory();
  return (
    <>
      <SubscriptionAppBar
        title="Update payment information"
        backButtonAnalyticsEvent={() => events.updatePaymentBack({ petId: device.pet?.id })}
        closeButtonAnalyticsEvent={() => events.updatePaymentClose({ petId: device.pet?.id })}
      />
      <div className={styles.billingInfo}>
        <PurchaseSubscriptionPaymentBody
          includeCancel={false}
          onSubmit={() => {
            history.replace(AppPaths.BillingInfoUpdate.Confirmation);
          }}
          onCancel={() => events.updatePaymentBack({ petId: device.pet?.id })}
        />
      </div>
    </>
  );
}
