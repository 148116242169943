import classNames from 'classnames';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import Chooser, { ChooserGroup } from '../Chooser';
import * as events from '../../lib/analytics/events';
import * as types from '../../types';
import SubscriptionOptionButton, { SubscriptionOption } from '../SubscriptionOptionDetails';
import styles from './SubscriptionOptions.module.scss';
import { showNanoVariant } from '../NanoBanner/NanoBanner';
import NanoValueProps from '../../views/ProductDetails/Series3CollarDetails/components/NanoValueProps/NanoValueProps';
import { getFiGrowthBook } from '../../lib/growthbook';

export interface CouponPillDisplay {
  shouldShow: boolean;
  title?: string;
  subtitle?: string;
}

interface ChooserGroupsProps {
  allowNoSubscription?: boolean;
  subscriptionProducts: SubscriptionOption[];
  showReferralPill?: boolean;
  couponPillDisplay?: CouponPillDisplay;
  showUpsellsInChooser?: boolean;
  monthlyUpgrade?: boolean;
  showSubscriptionExtension?: boolean;
  showSubscriptionLengthDiscount?: boolean;
  prominentPricingDesign?: boolean;
  disableSavingsDisplay?: boolean;
}

export function useSubscriptionChooserGroups({
  allowNoSubscription = false,
  subscriptionProducts,
  showReferralPill = false,
  couponPillDisplay,
  showUpsellsInChooser = false,
  monthlyUpgrade = false,
  showSubscriptionExtension = false,
  showSubscriptionLengthDiscount = false,
  prominentPricingDesign = false,
  disableSavingsDisplay = false,
}: ChooserGroupsProps) {
  // For the subscription chooser, we usually don't want to show any plans that are meant to be only for upsells which will
  // be made available via an interstitial modal, but the in-app cancel flow doesn't employ upselling in the same way,
  // so we set the showUpsellsInChooser and surface all purchasable plans.
  const filteredSubscriptionProducts = useMemo(
    () =>
      showUpsellsInChooser
        ? subscriptionProducts
        : subscriptionProducts.filter((subscriptionProduct) => !subscriptionProduct.upsellOnly),
    [subscriptionProducts, showUpsellsInChooser],
  );

  const chooserGroup = useCallback(
    (
      subscriptionProductGroup: SubscriptionOption[],
      groupLabel?: string,
      shouldShowReferralPill = false,
      couponPill?: CouponPillDisplay,
    ): ChooserGroup<string | undefined> => ({
      label: groupLabel,
      options: subscriptionProductGroup.map((subscriptionProduct) => ({
        content: (
          <SubscriptionOptionButton
            subscriptionProduct={subscriptionProduct}
            monthlyUpgrade={monthlyUpgrade}
            showSubscriptionExtension={showSubscriptionExtension}
            showSubscriptionLengthDiscount={showSubscriptionLengthDiscount}
            prominentPricingDesign={prominentPricingDesign}
            disableSavingsDisplay={disableSavingsDisplay}
          />
        ),
        value: subscriptionProduct.sku,
      })),
      showReferralPill: shouldShowReferralPill,
      couponPill,
    }),
    [
      disableSavingsDisplay,
      monthlyUpgrade,
      prominentPricingDesign,
      showSubscriptionExtension,
      showSubscriptionLengthDiscount,
    ],
  );

  const yearlyChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter(
          (subscriptionProduct) =>
            !subscriptionProduct.buyItMembership && subscriptionProduct.billingCadence === types.BillingCadence.Year,
        ),
      ),
    [chooserGroup, filteredSubscriptionProducts],
  );

  // Don't show nano ecomm test variant on in-app collar management screens
  const nanoEcomTestTwoEnabled =
    !window.location.pathname.startsWith('/subscription') &&
    (showNanoVariant() || getFiGrowthBook().getFeatureValue('nano-ecom-test-2', false));
  const monthToMonthChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter(
          (subscriptionProduct) =>
            !subscriptionProduct.buyItMembership &&
            subscriptionProduct.billingCadence === types.BillingCadence.Month &&
            subscriptionProduct.renewalMonths === 1,
        ),
        nanoEcomTestTwoEnabled ? 'Or try a monthly plan' : undefined,
      ),
    [chooserGroup, filteredSubscriptionProducts, nanoEcomTestTwoEnabled],
  );

  const prepaidChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter(
          (subscriptionProduct) =>
            !subscriptionProduct.buyItMembership &&
            subscriptionProduct.billingCadence === types.BillingCadence.Month &&
            subscriptionProduct.renewalMonths > 1,
        ),
        // Only show a header for the prepay section if they have any month-to-month options available to them
        monthToMonthChooserGroup.options.length > 0 ? 'Prepay and save' : undefined,
        showReferralPill,
        couponPillDisplay,
      ),
    [
      chooserGroup,
      monthToMonthChooserGroup.options.length,
      filteredSubscriptionProducts,
      showReferralPill,
      couponPillDisplay,
    ],
  );

  const buyItChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter((subscriptionProduct) => subscriptionProduct.buyItMembership),
        'Or pay one time', // Group header
      ),
    [chooserGroup, filteredSubscriptionProducts],
  );

  const chooserGroups = useMemo(() => {
    let groups = [yearlyChooserGroup, monthToMonthChooserGroup, prepaidChooserGroup, buyItChooserGroup];
    if (nanoEcomTestTwoEnabled) {
      groups = [prepaidChooserGroup, monthToMonthChooserGroup];
    }

    if (allowNoSubscription) {
      groups.push({
        options: [
          {
            content: <div className={styles.noSubscriptionOption}>I don't need a plan</div>,
            value: undefined,
          },
        ],
      });
    }
    return groups.filter((group) => group.options.length > 0);
  }, [
    allowNoSubscription,
    buyItChooserGroup,
    monthToMonthChooserGroup,
    prepaidChooserGroup,
    yearlyChooserGroup,
    nanoEcomTestTwoEnabled,
  ]);

  return chooserGroups;
}

interface SubscriptionOptionsProps {
  allowNoSubscription?: boolean;
  onSubscriptionChange: (sku: string | undefined) => void;
  selectedSubscriptionSku?: string;
  subscriptionProducts: types.ISubscriptionProduct[];
  title?: string;
  subtitle?: string;
  showReferralPill?: boolean;
  couponPillDisplay?: CouponPillDisplay;
  productName: string;
  subscriptionPickerRef?: MutableRefObject<HTMLDivElement | null>;
  startAsUnselected: boolean;
  disableSection: boolean;
  prominentPricingDesign?: boolean;
}

export default function SubscriptionOptions({
  allowNoSubscription = false,
  onSubscriptionChange,
  selectedSubscriptionSku,
  subscriptionProducts,
  title = 'Choose your plan',
  showReferralPill = false,
  subtitle,
  couponPillDisplay,
  productName,
  subscriptionPickerRef,
  startAsUnselected,
  disableSection,
  prominentPricingDesign = false,
}: SubscriptionOptionsProps) {
  const isUpgradePath = window.location.pathname.includes('upgrade');
  const isRedemptionPath = window.location.pathname.includes('redeem');
  const showSubscriptionLengthDiscount =
    !isUpgradePath && getFiGrowthBook().getFeatureValue('ecom-dollar-off-by-plan-length', false);

  const chooserGroups = useSubscriptionChooserGroups({
    allowNoSubscription,
    subscriptionProducts,
    showReferralPill,
    couponPillDisplay,
    showSubscriptionExtension: !showSubscriptionLengthDiscount && !isUpgradePath && !isRedemptionPath,
    showSubscriptionLengthDiscount: showSubscriptionLengthDiscount && !isRedemptionPath,
    prominentPricingDesign,
    disableSavingsDisplay: isRedemptionPath,
  });
  // Don't show nano ecomm test variant on in-app collar management screens
  const nanoEcomTestTwoEnabled =
    !window.location.pathname.startsWith('/subscription') &&
    (showNanoVariant() || getFiGrowthBook().getFeatureValue('nano-ecom-test-2', false));

  const [showSelected, setShowSelected] = useState<boolean>(!startAsUnselected);

  const needsSelection = false;
  return (
    <div
      className={classNames('subscription-options', styles.variant, { [styles.disabledSection]: disableSection })}
      ref={subscriptionPickerRef}
    >
      <div
        className={classNames(styles.variantTitleGroup, {
          [styles.variantTitleGroupTest]: nanoEcomTestTwoEnabled,
        })}
      >
        <div className={classNames(styles.variantTitle, { [styles.variantTitleError]: needsSelection })}>{title}</div>
        <div className={classNames(styles.variantSubtitle)}>{subtitle}</div>
      </div>
      {nanoEcomTestTwoEnabled && <NanoValueProps />}
      <Chooser
        autoHeight
        isDisabled={disableSection}
        onSelect={(subscriptionSku: string | undefined) => {
          setShowSelected(true);
          onSubscriptionChange(subscriptionSku);

          if (!subscriptionSku) {
            return;
          }

          const subscription = subscriptionProducts.find((product) => product.sku === subscriptionSku);
          if (subscription) {
            events.subscriptionSelected(subscription);
          }
        }}
        selectedOption={showSelected ? selectedSubscriptionSku : undefined}
        groups={chooserGroups}
      />
    </div>
  );
}
