import classNames from 'classnames';
import React from 'react';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import Button from '../../components/Button';
import WebViewAppBar from '../../components/WebViewAppBar/WebViewAppBar';
import HideChatWidget from '../../components/ZendeskChat/HideChatWidget';
import styles from './UpdateBillingInfo.module.scss';

export function UpdateBillingInfoConfirmation() {
  return (
    <>
      <div className={styles.confirmationContainer}>
        <WebViewAppBar className={styles.mobileOnly} title="Update payment information" />
        <div className={styles.section}>
          <div className={styles.checkIcon}>
            <CheckIcon width="64px" height="64px" />
          </div>
          <h2>Success!</h2>
          <p>Your Fi membership is now active.</p>
        </div>
        <div className={classNames(styles.actions, styles.mobileOnly)}>
          <Button onClick={() => window.open('fi://closeappview')}>Done</Button>
          <div className={styles.buttonSpacer} />
        </div>
        <HideChatWidget />
      </div>
    </>
  );
}
