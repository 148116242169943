import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../assets/images/icons/back.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import styles from './SubscriptionAppBar.module.scss';
import classNames from 'classnames';

/** Used on confirmation screens where no back button is needed, but we still want consistent whitespace at top of screen. */
export function SubscriptionAppBarSpacer() {
  return <div className={styles.container} />;
}

interface SubscriptionAppBarProps {
  title?: string;
  backButtonExitsWebview?: boolean;
  forceBackButtonBehavior?: () => void;
  backButtonAnalyticsEvent?: () => void;
  closeButtonAnalyticsEvent: () => void;
  noCloseButton?: boolean;
}

const CLEAR_STATE_FOR_PATHNAMES = ['/supplements'];

export default function SubscriptionAppBar({
  title,
  backButtonExitsWebview,
  backButtonAnalyticsEvent,
  forceBackButtonBehavior,
  closeButtonAnalyticsEvent,
  noCloseButton,
}: SubscriptionAppBarProps) {
  const history = useHistory();
  const disableBackButton = history.length === 1 && history.location.pathname.includes('/payment/update');
  return (
    <div className={styles.container}>
      <div className={styles.subAppBarContent}>
        <div
          className={classNames(styles.button, { [styles.hiddenBackButton]: disableBackButton })}
          onClick={() => {
            backButtonAnalyticsEvent && backButtonAnalyticsEvent();
            if (backButtonExitsWebview) {
              window.open('fi://closeappview');
            } else if (forceBackButtonBehavior) {
              forceBackButtonBehavior();
            } else {
              // Clear state for supplements pages so that toast notifications on splash page don't
              // surface when they're not meant to.
              CLEAR_STATE_FOR_PATHNAMES.forEach((pathname) => history.replace(pathname, {}));
              history.goBack();
            }
          }}
        >
          <BackIcon />
        </div>

        <div className={styles.title}>{title ?? ''}</div>

        <div
          className={styles.button}
          onClick={() => {
            closeButtonAnalyticsEvent();
            window.open('fi://closeappview');
          }}
        >
          {!noCloseButton && <CloseIcon />}
        </div>
      </div>
    </div>
  );
}
