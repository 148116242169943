import Button from '../../../../components/Button';
import Chooser from '../../../../components/Chooser';
import { gqlBillingCadenceToBillingCadence } from '../../../../lib/subscription';
import React, { useMemo } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { SubscriptionOption } from '../../../../components/SubscriptionOptionDetails';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { useSubscriptionChooserGroups } from '../../../../components/SubscriptionOptions/SubscriptionOptions';
import AppPaths from '../../../../AppPaths';
import { useHistory } from 'react-router-dom';
import CurrentSubscription from '../components/CurrentSubscription';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import { PurchaseSubscriptionCheckoutState } from '../../../PurchaseSubscription/PurchaseSubscriptionCheckout/PurchaseSubscriptionCheckout';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { cartActions } from '../../../../reducers/subscriptionCart';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscriptionCartMode } from '../../../../lib/cartModes';
import { getSubscriptionCategory, onValidAppVersion } from '../util/helpers';
import { SubscriptionCategory } from '../util/types';
import MembershipValueProps from '../../../ProductDetails/Series3CollarDetails/components/MembershipValueProps';
import valuePropStyles from '../../../ProductDetails/ProductDetails.module.scss';
import classNames from 'classnames';

function CancelButton({
  moduleId,
  petId,
  subscriptionCategory,
}: {
  moduleId: string;
  petId?: string;
  subscriptionCategory: SubscriptionCategory;
}) {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const pauseableClient = onValidAppVersion(device, window.location.search);
  const showPauseMembership = !!device.shouldOfferPauseMonthDuringCancellation && pauseableClient;
  console.log({ pauseableClient });

  return (
    <Button
      className={styles.button}
      tertiary={true}
      onClick={() => {
        events.requestToCancelMembershipClicked({
          petId,
          subscriptionCategory: subscriptionCategory,
        });

        if (showPauseMembership) {
          history.push({
            pathname: AppPaths.Subscription.PauseMembership(moduleId),
            state: { subscriptionCategory: subscriptionCategory },
            search: window.location.search,
          });
        } else {
          history.push({
            pathname: AppPaths.Subscription.Cancel(moduleId),
            state: { subscriptionCategory: subscriptionCategory },
            search: window.location.search,
          });
        }
      }}
    >
      Cancel Membership
    </Button>
  );
}

export default function ChangeSubscription() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  events.pageLoad({ petId: device.pet!.id }, 'Manage Membership');

  const applicableSubscriptionProducts: SubscriptionOption[] = useMemo(() => {
    if (device.hasSubscriptionOverride) {
      return []; // No upgrade options for an override subscription.
    }

    const result: SubscriptionOption[] = [];
    const currentRenewalMonths = device.subscription?.subscriptionOption.renewalMonths ?? 0;
    for (const option of device.purchasableSubscriptionOptions) {
      // Don't show options to "upgrade" to a lower tier subscription option.
      if (option.renewalMonths <= currentRenewalMonths) {
        continue;
      }

      result.push({
        ...option,
        billingCadence: gqlBillingCadenceToBillingCadence(option.billingCadence),
      });
    }
    return result;
  }, [device]);

  const selectedSubscriptionSku = useMemo(() => {
    const cartItem = Object.values(cart.cartItems)[0];
    if (!cartItem) {
      return undefined;
    }

    return cartItem.lineItem.sku;
  }, [cart.cartItems]);

  const selectedSubscriptionOption = useMemo(() => {
    return applicableSubscriptionProducts.find((option) => option.sku === selectedSubscriptionSku);
  }, [applicableSubscriptionProducts, selectedSubscriptionSku]);

  const chooserGroups = useSubscriptionChooserGroups({
    subscriptionProducts: applicableSubscriptionProducts,
  });

  const checkoutDisabled = selectedSubscriptionOption && selectedSubscriptionSku ? false : true;

  const showCancelButton = !device.hasSubscriptionOverride && !device.subscription?.subscriptionOption.buyItMembership;
  const showProducts = applicableSubscriptionProducts.length > 0;

  const currentSubscriptionSku = device.subscription?.subscriptionOption.sku;

  const currentSubscriptionCategory = getSubscriptionCategory(device.subscription?.subscriptionOption.renewalMonths);

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          title={'Manage Membership'}
          backButtonAnalyticsEvent={() => events.changeMembershipBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.changeMembershipClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Your current plan</h1>
          {/* This page is not accessible from the renew flow  */}
          <CurrentSubscription canRenew={false} />

          <h1>Prepay and save</h1>
          {showProducts ? (
            <>
              <Chooser
                onSelect={(selectedSku) => {
                  if (!selectedSku) {
                    return;
                  }

                  dispatch(
                    cartActions.setCartItem({
                      moduleId: device.moduleId,
                      sku: selectedSku,
                    }),
                  );
                }}
                selectedOption={selectedSubscriptionSku}
                groups={chooserGroups}
              />
              {device.currentCollarModel === 'S3' && (
                <div className={classNames('ecom-flow-revamp', valuePropStyles.inAppManagement)}>
                  <MembershipValueProps inAppManagementVariant={true} />
                </div>
              )}
            </>
          ) : (
            <div className={styles.content}>
              <p>You currently have the best value plan for your {device.currentCollarModel} collar.</p>
            </div>
          )}
          {showCancelButton && (
            <div className={classNames(styles.outsideButtonContainer)}>
              <CancelButton
                moduleId={device.moduleId}
                petId={device.pet?.id}
                subscriptionCategory={currentSubscriptionCategory}
              />
            </div>
          )}
        </div>
        {showProducts && (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              disabled={checkoutDisabled}
              onClick={() => {
                events.changeMembershipSaveClicked({
                  petId: device.pet?.id,
                  oldSku: currentSubscriptionSku,
                  newSku: selectedSubscriptionSku,
                });
                history.push({
                  pathname: AppPaths.Subscription.Checkout(device.moduleId),
                  state: {
                    petId: device.pet?.id,
                    oldSku: currentSubscriptionSku,
                    subscriptionOption: selectedSubscriptionOption,
                  } as PurchaseSubscriptionCheckoutState,
                  search: window.location.search,
                });
              }}
            >
              Save
            </Button>
            {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
            <div className={styles.buttonSpacer}></div>
          </div>
        )}
      </div>
    </>
  );
}
