import { FeaturesReady } from '@growthbook/growthbook-react';
import React, { useMemo } from 'react';
import Series3CollarDetails from './Series3CollarDetails';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import * as types from '../../../types';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import ReviewsCarousel from '../../../components/ReviewsCarousel';
import Footer from '../../../components/Footer';

export const RedeemSeries3Collar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const products = useSelector((state: types.AppState) => state.config.products);
  const product = useMemo(
    () => products.find((p) => p.id === 'smart-collar-v3') as types.ICollarKitProduct,
    [products],
  );
  const subscriptionProducts = useSubscriptionProducts(types.series3CollarId);
  const filteredSubscriptionProducts = useMemo(
    () => subscriptionProducts.filter((subscriptionProduct) => subscriptionProduct.renewalMonths >= 6),
    [subscriptionProducts],
  );

  return (
    <FeaturesReady>
      <Series3CollarDetails
        addBundleCartItem={(variantSku, subscriptionSku) => {
          dispatch(
            storeCartActions.addBundleCartItem({
              sku: variantSku,
              subscriptionSku: subscriptionSku,
            }),
          );

          history.push(AppPaths.Bag);
        }}
        priceInCents={product.priceInCents}
        productName={product.name}
        variants={product.variants}
        availableSubscriptions={filteredSubscriptionProducts}
        preselectedSubscriptionSku="sub-monthly-6m-001"
      />
      <ReviewsCarousel />
      <Footer />
    </FeaturesReady>
  );
};
