import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import { CartPricingProvider } from '../../contexts/CartPricingContext';
import { CheckoutContextProvider } from '../../lib/CheckoutContext';
import { CartModeProvider, CartModes, useSubscriptionCartMode } from '../../lib/cartModes';
import { priceCartForCheckout } from '../../lib/pricing';
import { PurchaseSubscriptionContextProvider } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import PrepaidActivationConfirmation from './PrepaidActivationConfirmation';
import PrepaidActivationCreditCard from './PrepaidActivationCreditCard';

function GiftFiActivationRouter() {
  const { cartSelector, checkoutType } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const fetchPricing = useCallback(() => priceCartForCheckout(cart, { checkoutType }), [cart, checkoutType]);

  return (
    <CartPricingProvider fetchPricing={fetchPricing}>
      <Switch>
        {/* Currently the gift activation blocker routes to a specific gift activation path. */}
        <SentryRoute exact path={AppPaths.GiftFiActivation.Root()} component={PrepaidActivationCreditCard} />
        <SentryRoute exact path={AppPaths.GiftFiActivation.Confirmation()} component={PrepaidActivationConfirmation} />
        {/* We will be migrating to a generic "prepaid activation" path for all prepaid activations. */}
        <SentryRoute exact path={AppPaths.PrepaidActivation.Root()} component={PrepaidActivationCreditCard} />
        <SentryRoute exact path={AppPaths.PrepaidActivation.Confirmation()} component={PrepaidActivationConfirmation} />
      </Switch>
    </CartPricingProvider>
  );
}

export default function PrepaidActivation() {
  return (
    <PurchaseSubscriptionContextProvider>
      <CartModeProvider mode={CartModes.purchaseSubscription}>
        <CheckoutContextProvider>
          <GiftFiActivationRouter />
        </CheckoutContextProvider>
      </CartModeProvider>
    </PurchaseSubscriptionContextProvider>
  );
}
