import { FeaturesReady, useGrowthBook } from '@growthbook/growthbook-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { centsToDollarsNumber, expectUnreachable, getPriceForRateUnit, RateUnit } from '../../lib/util';
import * as types from '../../types';
import styles from './SubscriptionOptionDetails.module.scss';
import { showNanoVariant } from '../NanoBanner/NanoBanner';
import { getFiGrowthBook } from '../../lib/growthbook';

export interface SubscriptionOption {
  billingCadence: types.BillingCadence;
  billingPeriodsPerTerm: number;
  buyItMembership?: boolean;
  displayedRate: string;
  displayedRateUnit: string;
  name: string;
  priceInCents: number;
  renewalMonths: number;
  renewalYears?: number | null;
  savings?: string | null;
  monthlyUpgradeSavings?: string | null;
  sku: string;
  upsellOnly?: boolean;
  valueLabel?: string | null;
  supportPlus?: boolean;
  couponExtensionMonths?: number | null;
  planSwitchCreditAppliedInCents?: number | null;
  planSwitchAmountChargedInCents?: number | null;
}

interface SubscriptionOptionButtonProps {
  subscriptionProduct: SubscriptionOption;
  renewalDate?: DateTime;
  expirationDate?: DateTime;
  pauseDate?: DateTime;
  disableSavingsDisplay?: boolean;
  overrideTitle?: string;
  pricingRateUnit?: RateUnit;
  renewScreens?: boolean;
  monthlyUpgrade?: boolean;
  showSubscriptionExtension?: boolean;
  showSubscriptionLengthDiscount?: boolean;
  prominentPricingDesign?: boolean;
  isPaused?: boolean;
}

function renewalTitle(renewalMonths: number, prominentPricingDesign?: boolean) {
  if (renewalMonths % 12 === 0) {
    const years = Math.round(renewalMonths / 12);
    return `${years} Year`;
  }

  if (renewalMonths === 1) {
    return prominentPricingDesign ? 'Month to Month' : 'Monthly';
  }

  return `${renewalMonths} Months`;
}

function billingCadenceDescription(subscription: SubscriptionOption) {
  let pricePeriod = '';
  const price = `$${centsToDollarsNumber(subscription.priceInCents)}`;
  if (subscription.renewalMonths === 1) {
    pricePeriod = `${price}/month`;
  } else if (subscription.renewalMonths === 6) {
    pricePeriod = `${price} semi-annually`;
  } else if (subscription.renewalMonths % 12 === 0) {
    const years = Math.round(subscription.renewalMonths / 12);
    if (years === 1) {
      pricePeriod = `${price} annually`;
    } else if (years === 2) {
      pricePeriod = `${price} biennially`;
    } else {
      pricePeriod = `${price} per ${years} years`;
    }
  } else {
    pricePeriod = `${price} per ${subscription.renewalMonths} months`;
  }

  return `Billed as ${pricePeriod}`;
}

function nextSignificantDateDescription(
  renewScreens: boolean,
  isPaused: boolean,
  renewalDate?: DateTime,
  expirationDate?: DateTime,
  pauseDate?: DateTime,
) {
  const DATE_FORMAT_TOKEN = 'LL/dd/yy, h:mma';
  if (renewScreens && expirationDate) {
    return <div className={styles.description}>Plan expires on {expirationDate.toFormat(DATE_FORMAT_TOKEN)}</div>;
  }

  if (isPaused && renewalDate && pauseDate) {
    const pauseDateIsInFuture = pauseDate > DateTime.now();

    return (
      <div className={styles.description}>
        Membership will {pauseDateIsInFuture ? 'pause' : 'resume'} on {renewalDate.toFormat(DATE_FORMAT_TOKEN)}
      </div>
    );
  }

  if (renewalDate) {
    return <div className={styles.description}>Renews {renewalDate.toFormat(DATE_FORMAT_TOKEN)}</div>;
  }
}

export function MonthlySubscriptionContent({
  subscriptionProduct,
  renewalDate,
  expirationDate,
  pauseDate,
  disableSavingsDisplay,
  overrideTitle,
  pricingRateUnit = 'week',
  renewScreens = false,
  monthlyUpgrade = false,
  showSubscriptionExtension = false, // If true, show extension promotional banner
  showSubscriptionLengthDiscount = false,
  prominentPricingDesign = false,
  isPaused = false,
}: SubscriptionOptionButtonProps) {
  // Don't show nano ecomm test variant on in-app collar management screens
  const nanoEcomTestTwoEnabled =
    !window.location.pathname.startsWith('/subscription') &&
    !window.location.pathname.startsWith('/redeem') &&
    (showNanoVariant() || getFiGrowthBook().getFeatureValue('nano-ecom-test-2', false));
  const { unit: displayRateUnit, displayRate } = getPriceForRateUnit(subscriptionProduct, pricingRateUnit);

  let prepaidPeriodTitle: string | undefined =
    overrideTitle ?? renewalTitle(subscriptionProduct.renewalMonths, prominentPricingDesign);
  if (nanoEcomTestTwoEnabled && subscriptionProduct.renewalMonths === 1) {
    prepaidPeriodTitle = undefined;
  }
  const savingsAmount = monthlyUpgrade ? subscriptionProduct.monthlyUpgradeSavings : subscriptionProduct.savings;
  const savingsPill = savingsAmount && !disableSavingsDisplay && (
    <div className={styles.savingsPill}>{savingsAmount}</div>
  );

  return (
    <div
      className={classNames(styles.container, {
        [styles.prominentPricingDesign]: prominentPricingDesign,
      })}
    >
      <div className={styles.column}>
        {prepaidPeriodTitle && (
          <div className={classNames(styles.row, styles.title)}>
            {prepaidPeriodTitle && <div className={styles.billingCadence}>{prepaidPeriodTitle}</div>}
            {prominentPricingDesign && savingsPill}
            {nanoEcomTestTwoEnabled && subscriptionProduct.renewalMonths === 12 && (
              <div className={styles.mostPopularPill}>MOST POPULAR</div>
            )}
          </div>
        )}
        {prominentPricingDesign ? (
          <div className={styles.price}>
            <span
              className={classNames(styles.priceBold, {
                [styles.monthToMonthPrice]: subscriptionProduct.renewalMonths === 1,
              })}
            >
              ${centsToDollarsNumber(subscriptionProduct.priceInCents)}
              {subscriptionProduct.renewalMonths === 1 && '/mo'}
            </span>{' '}
            {subscriptionProduct.renewalMonths > 1 && (
              <span className={styles.description}>
                as low as {subscriptionProduct.displayedRate}/
                {subscriptionProduct.displayedRateUnit === 'month' ? 'mo' : subscriptionProduct.displayedRateUnit}
              </span>
            )}
          </div>
        ) : (
          <>
            <div className={styles.price}>
              <span className={styles.priceBold}>{displayRate}</span>/{displayRateUnit}
            </div>
            <div className={styles.description}>{billingCadenceDescription(subscriptionProduct)}</div>
          </>
        )}
        {nextSignificantDateDescription(renewScreens, isPaused, renewalDate, expirationDate, pauseDate)}
      </div>
      <div className={classNames(styles.column, styles.right)}>
        {prominentPricingDesign ? (
          <>
            {showSubscriptionExtension && subscriptionProduct.couponExtensionMonths && (
              <div className={styles.couponExtension}>
                <span className={styles.description}>
                  +{subscriptionProduct.couponExtensionMonths} month
                  {subscriptionProduct.couponExtensionMonths > 1 ? 's' : ''} free
                </span>
                <span className={styles.title}>EXCLUSIVE OFFER</span>
              </div>
            )}
          </>
        ) : (
          <>
            {savingsPill}
            {showSubscriptionExtension && subscriptionProduct.couponExtensionMonths && (
              <div className={styles.couponExtension}>
                <span className={styles.title}>EXCLUSIVE OFFER</span>
                <span className={styles.description}>
                  +{subscriptionProduct.couponExtensionMonths} month
                  {subscriptionProduct.couponExtensionMonths > 1 ? 's' : ''} free
                </span>
              </div>
            )}
          </>
        )}

        {prominentPricingDesign ? (
          <>
            {showSubscriptionLengthDiscount &&
              getSubscriptionLengthBasedDollarExperimentDiscount(subscriptionProduct) !== null && (
                <div className={styles.couponExtension}>
                  <span className={styles.description}>
                    {getSubscriptionLengthBasedDollarExperimentDiscount(subscriptionProduct)} off
                  </span>
                  <span className={styles.title}>EXCLUSIVE OFFER</span>
                </div>
              )}
          </>
        ) : (
          <>
            {showSubscriptionLengthDiscount &&
              getSubscriptionLengthBasedDollarExperimentDiscount(subscriptionProduct) !== null && (
                <div className={styles.couponExtension}>
                  <span className={styles.title}>EXCLUSIVE OFFER</span>
                  <span className={styles.description}>
                    {getSubscriptionLengthBasedDollarExperimentDiscount(subscriptionProduct)} off
                  </span>
                </div>
              )}
          </>
        )}
      </div>
      {(renewScreens || isPaused) && (
        <div className={classNames(styles.column, styles.right)}>
          <span className={classNames(styles.valueLabel, styles.deactivated)}>
            {renewScreens ? 'CANCELED' : 'PAUSED'}
          </span>
        </div>
      )}
    </div>
  );
}

export function YearlySubscriptionContent({
  subscriptionProduct,
  renewalDate,
  expirationDate,
  pauseDate,
  disableSavingsDisplay,
  overrideTitle,
  renewScreens = false,
  isPaused = false,
}: SubscriptionOptionButtonProps) {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.title)}>
          <div className={styles.billingCadence}>
            {overrideTitle ?? renewalTitle(subscriptionProduct.renewalMonths)}
          </div>
          {subscriptionProduct.savings && !disableSavingsDisplay && (
            <div className={styles.savingsPill}>{subscriptionProduct.savings}</div>
          )}
        </div>
        <div className={styles.price}>
          <span className={styles.priceBold}>{subscriptionProduct.displayedRate}</span>/
          {subscriptionProduct.displayedRateUnit}
        </div>
        <div className={styles.description}>{billingCadenceDescription(subscriptionProduct)}</div>
        {nextSignificantDateDescription(renewScreens, isPaused, renewalDate, expirationDate, pauseDate)}
      </div>
      {renewScreens || isPaused ? (
        <div className={classNames(styles.column, styles.right)}>
          <span className={classNames(styles.valueLabel, styles.deactivated)}>
            {renewScreens ? 'CANCELED' : 'PAUSED'}
          </span>
        </div>
      ) : (
        <div className={styles.column}></div>
      )}
    </div>
  );
}

export function BuyItSubscriptionContent({ subscriptionProduct }: SubscriptionOptionButtonProps) {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.title)}>
          <div className={styles.billingCadence}>BUY IT</div>
        </div>
        <div className={styles.price}>{subscriptionProduct.displayedRate}</div>
        <div className={styles.description}>One time payment</div>
      </div>
      <div className={classNames(styles.column, styles.right)}>
        <div className={styles.supportPlusWrapper}>
          {subscriptionProduct.supportPlus && (
            <>
              <div className={classNames(styles.supportLabel)}>Support+ included</div>
              <div className={classNames(styles.supportDescription)}>Prioritized Phone Support</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function SubscriptionOverrideContent() {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.title)}>
          <div className={styles.billingCadence}>SUBSCRIPTION OVERRIDE</div>
        </div>
        <div className={styles.price}>$0</div>
        <div className={styles.description}>Renews indefinitely</div>
      </div>
      <div className={styles.column}></div>
    </div>
  );
}

export function LifetimeSubscriptionContent() {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.title)}>
          <div className={styles.billingCadence}>LIFETIME MEMBERSHIP</div>
        </div>
        <div className={styles.price}></div>
        <div className={styles.description}>Renews indefinitely</div>
      </div>
      <div className={styles.column}></div>
    </div>
  );
}

export default function SubscriptionOptionButton({
  disableSavingsDisplay,
  renewalDate,
  expirationDate,
  pauseDate,
  subscriptionProduct,
  overrideTitle,
  renewScreens,
  monthlyUpgrade = false,
  showSubscriptionExtension = false,
  showSubscriptionLengthDiscount = false,
  prominentPricingDesign = false,
  isPaused = false,
}: SubscriptionOptionButtonProps) {
  // This can be undefined if we're not wrapped in a GrowthBookProvider. We only use the GrowthBookProvider in
  // the main store flow so in-app webviews won't have this.
  const growthBookEnabled = !!useGrowthBook();

  if (subscriptionProduct.buyItMembership) {
    return <BuyItSubscriptionContent subscriptionProduct={subscriptionProduct} />;
  } else if (subscriptionProduct.billingCadence === types.BillingCadence.Month) {
    const monthlySubscriptionContent = (
      <MonthlySubscriptionContent
        disableSavingsDisplay={disableSavingsDisplay}
        renewalDate={renewalDate}
        expirationDate={expirationDate}
        pauseDate={pauseDate}
        overrideTitle={overrideTitle}
        subscriptionProduct={subscriptionProduct}
        pricingRateUnit={'week'}
        renewScreens={renewScreens}
        monthlyUpgrade={monthlyUpgrade}
        showSubscriptionExtension={showSubscriptionExtension}
        showSubscriptionLengthDiscount={showSubscriptionLengthDiscount}
        prominentPricingDesign={prominentPricingDesign}
        isPaused={isPaused}
      />
    );

    return growthBookEnabled ? <FeaturesReady>{monthlySubscriptionContent}</FeaturesReady> : monthlySubscriptionContent;
  } else if (subscriptionProduct.billingCadence === types.BillingCadence.Year) {
    return (
      <YearlySubscriptionContent
        disableSavingsDisplay={disableSavingsDisplay}
        renewalDate={renewalDate}
        expirationDate={expirationDate}
        pauseDate={pauseDate}
        overrideTitle={overrideTitle}
        subscriptionProduct={subscriptionProduct}
        renewScreens={renewScreens}
        isPaused={isPaused}
      />
    );
  } else if (subscriptionProduct.billingCadence === types.BillingCadence.Week) {
    // We don't support weekly subscriptions yet as they are currently only used by suplement subscriptions
    return null;
  } else {
    expectUnreachable(subscriptionProduct.billingCadence);
    return null;
  }
}

// This function is only tied to a specific pricing experiment and should be removed once the experiment is over
function getSubscriptionLengthBasedDollarExperimentDiscount(subscription: SubscriptionOption): string | null {
  switch (subscription.renewalMonths) {
    case 6:
      return '$35';
    case 12:
      return '$50';
    case 24:
      return '$70';
    default:
      return null;
  }
}
