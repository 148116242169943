import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import CartSummary from '../../../components/CartSummary';
import ItemsInCart from '../../../components/ItemsInCart';
import { CartPricingProvider } from '../../../contexts/CartPricingContext';
import { useSeries3UpgradeContext } from '../../../contexts/Series3UpgradeContext';
import * as events from '../../../lib/analytics/events';
import { useCartMode } from '../../../lib/cartModes';
import CheckoutContext, { CheckoutContextProvider } from '../../../lib/CheckoutContext';
import { priceCartForPreview } from '../../../lib/pricing';
import * as types from '../../../types';
import { PetList } from '../components/PetList';
import styles from './UpgradeCart.module.scss';
import useUpgradePetId from '../../../hooks/useUpgradePetId';
import { SERIES_3_UPGRADE_V2_COUPON_CODE } from '../../Series3UpgradeV2/Series3UpgradePDPV2';

function useTracking({ cartItems }: types.Cart) {
  const [hasLoggedPageLoad, setHasLoggedPageLoad] = useState(false);
  useEffect(() => {
    if (!hasLoggedPageLoad && Object.keys(cartItems).length > 0) {
      events.cartPage.viewed(cartItems);
      setHasLoggedPageLoad(true);
    }
  }, [cartItems, hasLoggedPageLoad]);
}

function useEmptyCartPath() {
  const { rootPath } = useCartMode();
  const contextPetId = useSelector((state: types.AppState) => state.series3UpgradeShop.upgradePetContext.petId);

  if (contextPetId) {
    return AppPaths.Series3Upgrade.SplashPageForPet(contextPetId);
  }

  return rootPath;
}

function CartContainer() {
  const { upgradeablePets } = useSeries3UpgradeContext();
  const { cart } = useContext(CheckoutContext);
  const petId = useUpgradePetId();
  const emptyCartPath = useEmptyCartPath();
  const isCartEmpty = Object.keys(cart.cartItems).length <= 0;
  const inAppCancellationFlow = useSelector(
    (state: types.AppState) => state.series3UpgradeShop.upgradePetContext.inAppCancellationFlow,
  );

  useTracking(cart);

  const fetchPricing = useCallback(() => {
    return priceCartForPreview(cart, {
      checkoutType: types.CheckoutType.Upgrade,
    });
  }, [cart]);

  if (isCartEmpty) {
    return <Redirect to={emptyCartPath} />;
  }

  return (
    <div className={styles.main}>
      {upgradeablePets.length > 0 && cart.couponCode !== SERIES_3_UPGRADE_V2_COUPON_CODE && (
        <div className={styles.leftContainer}>
          <h3 className={styles.sectionHeader}>Add collar</h3>
          <PetList upgradeablePets={upgradeablePets} />
        </div>
      )}
      <div className={styles.rightContainer}>
        <h3 className={classNames(styles.sectionHeader, styles.cartHeader)}>Cart</h3>
        <CartPricingProvider fetchPricing={fetchPricing}>
          <div className={styles.cartItemsContainer}>
            <div className={styles.cartItems}>
              <ItemsInCart
                allowRemoveCartItem={cart.couponCode !== SERIES_3_UPGRADE_V2_COUPON_CODE}
                allowRemoveCode={false}
              />
            </div>
          </div>
          <CartSummary
            allowPromoCodes={false}
            onCheckOut={(checkoutCart, cartPricing) => {
              events.cartPage.checkout(checkoutCart.cartItems, cartPricing);
              inAppCancellationFlow && events.inAppCancellation.upgradeToS3Checkout({ petId });
            }}
          />
        </CartPricingProvider>
      </div>
    </div>
  );
}

export default function UpgradeCart() {
  // Cart needs to be wrapped with checkout context to handle Apple Pay, which is a one-click checkout from the cart
  return (
    <CheckoutContextProvider>
      <CartContainer />
    </CheckoutContextProvider>
  );
}
