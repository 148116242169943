import { Alert } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { useError } from '../../components/ErrorMessage';
import WebViewAppBar from '../../components/WebViewAppBar';
import HideChatWidget from '../../components/ZendeskChat/HideChatWidget';
import { webApiClient } from '../../lib/fi-api/apiUtils';
import { gqlTypes } from '../../types';
import EditCreditCardBillingInfo from '../Checkout/pages/Billing/EditCreditCardBillingInfo';
import styles from './UpdateBillingInfo.module.scss';
import IconAlert from '../../assets/images/icons/icon_alert.svg';

export function UpdateBillingInfoCreditCard() {
  const { error, setError, clearError } = useError();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const accountCode = queryParameters.get('accountCode');

  const onUpdate = useCallback(
    async (args: { input: gqlTypes.BillingAccountInput }) => {
      setSubmitting(true);
      try {
        await webApiClient.put('/api/ecommerce/billing-info', { ...args.input, accountCode });
      } catch (e) {
        setError('There was an error saving your info, recheck and try again.');
        setSubmitting(false);
        return;
      }
      setSubmitting(false);
      console.log('Billing info updated');
      history.replace(AppPaths.BillingInfoUpdate.Confirmation);
    },
    [accountCode, history, setError],
  );

  return (
    <div className={styles.container}>
      <div className={styles.desktopTopBar}></div>
      <WebViewAppBar className={styles.mobileOnly} title="Update payment information" />
      <div className={styles.content}>
        <h1 className={styles.desktopOnly}>Update payment information</h1>
        <div className={styles.section}>
          <EditCreditCardBillingInfo
            actionText="Save"
            updateBillingInfo={onUpdate}
            billingInfo={null}
            submitting={submitting}
            setError={setError}
            clearError={clearError}
            compactForm={true}
            outlineErrorFields={true}
          />
        </div>
      </div>
      <HideChatWidget />
      {error && (
        <Alert
          style={{
            background: '#E0352A',
            color: '#FFFFFF',
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '120%',
          }}
          className={styles.errorAlert}
          icon={<img className={styles.errorAlertIcon} src={IconAlert} alt="!" />}
          action={
            <div className={styles.errorAlertDismiss} onClick={() => clearError()}>
              Dismiss
            </div>
          }
        >
          There was an error saving your info, recheck and try again.
        </Alert>
      )}
    </div>
  );
}
