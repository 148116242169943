import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Series3UpgradeV2.module.scss';
import valuePropStyles from '../ProductDetails/ProductDetails.module.scss';
import ValuePropsBox, { ValueProp } from '../ProductDetails/Series3CollarDetails/components/ValuePropsBox';
import { ReactComponent as IconCompact } from '../../assets/images/icons/icon_compact.svg';
import { ReactComponent as IconBroadcast } from '../../assets/images/icons/icon_broadcast.svg';
import { ReactComponent as IconLte } from '../../assets/images/icons/icon_lte.svg';
import { ReactComponent as IconReinforced } from '../../assets/images/icons/icon_reinforced.svg';
import { ReactComponent as IconDrip } from '../../assets/images/icons/icon_drip.svg';
import { ReactComponent as IconLightning } from '../../assets/images/icons/icon_lightning.svg';
import classNames from 'classnames';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Series3UpgradeContext from '../../contexts/Series3UpgradeContext';
import AppPaths from '../../AppPaths';
import { cartActions as upgradeCartActions } from '../../reducers/series3UpgradeCart';
import { series3UpgradeShopActions } from '../../reducers/series3UpgradeShop';
import * as types from '../../types';

export const WHATS_NEW_VALUE_PROPS: ValueProp[] = [
  {
    icon: <IconBroadcast />,
    text: (
      <div className={classNames(valuePropStyles.valueProp, valuePropStyles.valuePropCheat)}>
        <span className={valuePropStyles.valuePropBadge}>NEW</span>{' '}
        <span className={valuePropStyles.valuePropHeader}>Real-time Live GPS.</span>{' '}
        <span className={valuePropStyles.valuePropBody}>Our fastest and most advanced tracking.</span>
      </div>
    ),
  },
  {
    icon: <IconCompact />,
    text: (
      <div className={classNames(valuePropStyles.valueProp, valuePropStyles.valuePropCheat)}>
        <span className={valuePropStyles.valuePropBadge}>NEW</span>{' '}
        <span className={valuePropStyles.valuePropHeader}>50% smaller.</span>{' '}
        <span className={valuePropStyles.valuePropBody}>Low-profile design to better fit dogs of all sizes..</span>
      </div>
    ),
  },
  {
    icon: <IconLte />,
    text: (
      <div className={valuePropStyles.valueProp}>
        <span className={valuePropStyles.valuePropHeader}>Unlimited GPS + LTE-M coverage.</span>{' '}
        <span className={valuePropStyles.valuePropBody}>Track in most remote areas and on hikes.</span>
      </div>
    ),
  },
  {
    icon: <IconReinforced />,
    text: (
      <div className={valuePropStyles.valueProp}>
        <span className={valuePropStyles.valuePropHeader}>Stainless Steel Frame.</span>{' '}
        <span className={valuePropStyles.valuePropBody}>Built for up to 500 lb of tug resistance</span>
      </div>
    ),
  },
  {
    icon: <IconDrip />,
    text: (
      <div className={valuePropStyles.valueProp}>
        <span className={valuePropStyles.valuePropHeader}>IP68 & IP66k.</span>{' '}
        <span className={valuePropStyles.valuePropBody}>More waterproof than ever before.</span>
      </div>
    ),
  },
  {
    icon: <IconLightning />,
    text: (
      <div className={valuePropStyles.valueProp}>
        <span className={valuePropStyles.valuePropHeader}>Long battery life.</span>{' '}
        <span className={valuePropStyles.valuePropBody}>Up to 3 months from one charge.</span>
      </div>
    ),
  },
];

export default function Series3UpgradeV2() {
  const history = useHistory();
  const upgradeContext = useContext(Series3UpgradeContext);
  const series3UpgradeCart = useSelector((state: types.AppState) => state.series3UpgradeShop.cart);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const moduleId = searchParams.get('moduleId');

  // Since we upgrade one pet at a time, clear out the cart when landing here just in case someone went through
  // checkout halfway but didn't complete and re-started with a different pet.
  useEffect(() => {
    // Clear cart and checkout progress upon mount
    if (Object.keys(series3UpgradeCart.cartItems).length > 0) {
      dispatch(series3UpgradeShopActions.resetCheckout());
      dispatch(upgradeCartActions.resetCart());
    }
  }, [dispatch, series3UpgradeCart.cartItems, series3UpgradeCart.couponCode]);

  if (!upgradeContext || !moduleId || !upgradeContext.upgradeV2ModuleIds.includes(moduleId)) {
    return <Redirect to={AppPaths.NotFound} />;
  }

  return (
    <div className={classNames('black-friday-upgrade', styles.upgradeScreenContainer)}>
      <div className={styles.offerTag}>CYBER MONDAY OFFER</div>
      <div className={styles.header}>UPGRADE AND SAVE</div>
      <div className={styles.description}>
        Unleash better performance in a more durable, sleeker collar with the Fi Series 3.
      </div>
      <div className={styles.offerContainer}>
        <div className={styles.offerTitle}>Cyber Monday Offer</div>
        <div className={styles.offerPricing}>
          <div className={styles.dealPrice}>$99&nbsp;</div>
          <div className={styles.regularPrice}>$189</div>
        </div>
        <div className={styles.offerBulletPoints}>
          <div className={styles.offerBulletPoint}>&nbsp;•&nbsp;New Series 3 Collar</div>
          <div className={styles.offerBulletPoint}>&nbsp;•&nbsp;One year Fi membership</div>
        </div>
      </div>
      <div className={styles.collarImageContainer} />
      <div className={styles.whatsNewHeader}>SERIES 3: WHAT’S NEW</div>
      <div className={styles.valuePropsContainer}>
        <ValuePropsBox valueProps={WHATS_NEW_VALUE_PROPS} />
      </div>
      <button
        className={styles.getSeriesThreeButton}
        onClick={() => history.push(AppPaths.Series3UpgradeV2.ProductSelector(moduleId))}
      >
        Get Series 3
      </button>
      <div className={styles.disclaimer}>
        <div>S3 Membership replaces your S2 Subscription and renews at $189/year.</div>
      </div>
    </div>
  );
}
