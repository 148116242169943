import React from 'react';
import styles from './PrepaidActivation.module.scss';

interface PrepaidSubscriptionInfoProps {
  subscriptionOptionName: string;
  price: string;
  subtext?: string;
  dueToday: string;
}

export default function PrepaidSubscriptionInfo({
  subscriptionOptionName,
  price,
  subtext,
  dueToday,
}: PrepaidSubscriptionInfoProps) {
  return (
    <div className={styles.subscriptionInfoCard}>
      <div className={styles.subscriptionLabel}>YOUR FI MEMBERSHIP</div>

      <div className={styles.subscriptionInfo}>
        <div className={styles.subscriptionRow}>
          <div className={styles.title}>{subscriptionOptionName}</div>
          <div className={styles.price}>{price}</div>
        </div>

        {subtext && <div className={styles.subscriptionSubtext}>{subtext}</div>}
      </div>

      <div className={styles.dueRow}>
        <div className={styles.label}>Due Today</div>
        <div className={styles.amount}>{dueToday}</div>
      </div>
    </div>
  );
}
