import { Query } from '@apollo/client/react/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { identifyUser } from '../../lib/analytics/identifyUser';
import { billingAccountQuery } from '../../graphql-operations';
import { gqlTypes } from '../../types';
import useCheckoutPaths from '../../hooks/useCheckoutPaths';
import * as events from '../../lib/analytics/events';
import ThankYouLocationState from '../../models/ThankYouLocationState';
import * as types from '../../types';

interface IFinishedRedirectProps {
  cart: types.Cart;
  cartPricing: types.CartPricing;
  isReturningCustomer: boolean;
  orderId: string;
}

export default function FinishedRedirect({ cart, cartPricing, orderId, isReturningCustomer }: IFinishedRedirectProps) {
  const session = useSelector((state: types.AppState) => state.session);
  const checkoutPaths = useCheckoutPaths();
  const showSupplementsUsell = true;

  const createRedirect = (state: ThankYouLocationState) => {
    return (
      <Redirect
        to={{
          pathname: showSupplementsUsell ? checkoutPaths.Supplements : checkoutPaths.ThankYou,
          state,
        }}
      />
    );
  };

  // This is a bit janky of a way to handle errors. Create empty information to display on the thank you page.
  // Technically the user has completed their purchase at this point - there was no error there, so displaying
  // the thank you page seems less confusing than displaying an error where they might not be sure if the
  // purchase went through.
  // This should be an extreme edge case, since at this point we should definitely have a billing account
  // as we just made a purchase.
  const createErrorRedirect = () =>
    createRedirect({
      orderId,
      orderedCart: cart,
      orderedCartPricing: cartPricing,
    });

  return (
    <Query<gqlTypes.billingAccount>
      query={billingAccountQuery}
      fetchPolicy="network-only"
      onCompleted={(data) => {
        const billingAccount = data.currentUser.billingAccount;
        if (billingAccount?.billingInfo && orderId) {
          const { id: userId, firstName, lastName } = data.currentUser;
          const { billingInfo, address } = billingAccount;

          // Identify the user with their information before tracking the order completed event
          identifyUser(userId, {
            email: session?.email,
            name: {
              firstName,
              lastName: lastName ?? undefined,
            },
            billingInfo,
            shippingAddress: address,
          });

          events.orderCompleted(cart, cartPricing, orderId, address ?? undefined, billingInfo, {
            isReturningCustomer,
            integrations: {
              // Tracks this event here for all integrations except Facebook. For us, it's important that we
              // add the customer PII to the Facebook event, but the Facebook Pixel won't include that PII
              // if you do identify and then an immediate track. So instead, for ApplePay only, we will just rely
              // on our own analytics backend to fire off the FB Purchase event via the Conversions API.
              // If we fire Purchase events from both here and the backend Conversions API, Facebook will dedupe
              // and always favor the frontend Pixel event, which in our case would not have the customer PII.
              'Facebook Pixel': false,
            },
          });
        }
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return null;
        }
        if (error || !data) {
          return createErrorRedirect();
        }

        const info: ThankYouLocationState = {
          orderId,
          orderedCart: cart,
          orderedCartPricing: cartPricing,
        };

        return createRedirect(info);
      }}
    </Query>
  );
}
