import React, { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import styles from './Series3UpgradePDPV2.module.scss';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import CollarAddToBagButton from '../ProductDetails/Series3CollarDetails/components/CollarAddToBagButton';
import HideChatWidget from '../../components/ZendeskChat/HideChatWidget';
import classNames from 'classnames';
import { useOnChangeHandlers } from '../ProductDetails/Series3CollarDetails/Series3CollarDetails';
import useVariantSelector from '../ProductDetails/hooks/useVariantSelector';
import { CarouselController } from '../../components/Carousel';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../types';
import useSubscriptionProducts from '../../hooks/useSubscriptionProducts';
import useSubscriptionSelector from '../ProductDetails/hooks/useSubscriptionSelector';
import { DesktopImageGallery } from '../ProductDetails/Series3CollarDetails/components/ImageGallery';
import NavBar from '../../components/AppBar/NavBar';
import * as events from '../../lib/analytics/events';
import { cartActions as series3UpgradeCartActions } from '../../reducers/series3UpgradeCart';
import { commonDetailsForSize } from '../../lib/size';
import { commonDetailsForSubscription } from '../../lib/subscription';
import ColorOptions from '../ProductDetails/components/ColorOptions';
import SizeOptions from '../ProductDetails/components/SizeOptions';
import {
  BandVariantGroup,
  getFilteredVariants,
} from '../ProductDetails/components/BandVariantOptions/BandVariantOptions';
import Series3UpgradeContext from '../../contexts/Series3UpgradeContext';
import ProductSummary from '../ProductDetails/Series3CollarDetails/components/ProductSummary';
import FAQ from '../ProductDetails/Series3CollarDetails/components/FAQ';

export const SERIES_3_UPGRADE_V2_COUPON_CODE = 'series-3-upgrade-v2';

const ctaButtonText = (hasUpdatedSize: boolean): string => {
  if (!hasUpdatedSize) {
    return 'Pick Your Size';
  }

  return 'Add To Cart';
};

const ctaDescriptionText = (
  selectedVariant: types.IVariant,
  selectedSubscription: types.ISubscriptionProduct | undefined,
): string => {
  const toTitleCase = (str: string): string => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  let description =
    toTitleCase(selectedVariant.options.color) + ' · ' + commonDetailsForSize(selectedVariant.options.size).sizeName;
  if (selectedSubscription) {
    description += ' · ' + commonDetailsForSubscription(selectedSubscription).subscriptionName;
  }
  return description;
};

export default function Series3UpgradePDPV2() {
  const upgradeContext = useContext(Series3UpgradeContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { moduleId } = useParams<{ moduleId: string }>();

  const sizePickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const desktopCarouselControllerRef = useRef<CarouselController>();
  const [currentCarouselImage, setCurrentCarouselImage] = useState<number>(0);

  const products = useSelector((state: types.AppState) => state.config.products);
  const product = useMemo(
    () => products.find((p) => p.id === 'smart-collar-v3') as types.ICollarKitProduct,
    [products],
  );
  const subscriptionProducts: types.ISubscriptionProduct[] = useSubscriptionProducts(types.series3CollarId);

  const { selectedSubscription } = useSubscriptionSelector(subscriptionProducts, 'sub-monthly-12m-006', false);
  const { onColorChange, onSizeChange, onSelectedVariantChange } = useOnChangeHandlers({
    desktopCarouselControllerRef,
    priceInCents: product.priceInCents,
    productName: product.name,
    subscriptionPriceInCents: selectedSubscription?.priceInCents,
    currentCarouselImage,
    isUpgradePath: false,
  });

  const { changeVariantOptions, selectedVariant, hasUpdatedSize } = useVariantSelector({
    onColorChange,
    onSelectedVariantChange,
    onSizeChange,
    variants: product.variants,
    useQueryParams: false,
  });

  useEffect(() => {
    events.series3CollarViewed();
  }, []);

  const addToBag = useCallback(
    (variant: types.IVariant, subscriptionProduct: types.ISubscriptionProduct) => {
      events.series3CollarAddedToBag({
        color: variant.options.color,
        priceInCents: product.priceInCents,
        productName: product.name,
        size: variant.options.size,
        sku: variant.sku,
      });
      events.series3CollarActionButtonClick({
        color: variant.options.color,
        priceInCents: product.priceInCents,
        productName: product.name,
        size: variant.options.size,
        sku: variant.sku,
      });

      events.series3SubscriptionAddedToBag({
        priceInCents: subscriptionProduct.priceInCents,
        productId: subscriptionProduct.id,
        productName: subscriptionProduct.name,
        sku: subscriptionProduct.sku,
      });

      dispatch(series3UpgradeCartActions.addCoupon(SERIES_3_UPGRADE_V2_COUPON_CODE));
      dispatch(
        series3UpgradeCartActions.addUpgradeCartItem({
          sku: variant.sku,
          subscriptionSku: subscriptionProduct.sku,
          moduleId: moduleId,
        }),
      );

      history.push(AppPaths.Series3UpgradeV2.Bag);
    },
    [product.priceInCents, product.name, dispatch, moduleId, history],
  );

  const onAddToBagClicked = useCallback(() => {
    const offsetY = Array.from(document.querySelectorAll('.promotionBanner')).reduce(
      (total, el) => (total + (el as HTMLElement).style.zIndex === '-1' ? 0 : (el as HTMLElement).offsetHeight),
      0,
    );

    if (!hasUpdatedSize) {
      if (sizePickerRef.current) {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: { y: sizePickerRef.current, offsetY: offsetY },
          ease: 'scrollTo',
        });
      }
      return false;
    }

    addToBag(selectedVariant, selectedSubscription!);
    return true;
  }, [selectedSubscription, addToBag, selectedVariant, hasUpdatedSize]);

  if (!upgradeContext || !moduleId || !upgradeContext.upgradeV2ModuleIds.includes(moduleId)) {
    return <Redirect to={AppPaths.NotFound} />;
  }

  return (
    <>
      <div
        className={classNames(
          'ecom-black-friday-upgrade',
          styles.productContainer,
          styles.ecomFlowRevamp,
          'ecom-flow-revamp',
          {
            'ecom-flow-revamp-light': currentCarouselImage === 0 || currentCarouselImage === 1,
          },
        )}
      >
        <div className={classNames(styles.galleryContainer, { [styles.nanoGalleryContainer]: true })}>
          <DesktopImageGallery
            carouselControllerRef={desktopCarouselControllerRef}
            selectedVariant={selectedVariant}
            onChange={(idx) => setCurrentCarouselImage(idx)}
          >
            <NavBar />
          </DesktopImageGallery>
        </div>
        <div className={classNames(styles.productDetails, 'product-details')}>
          <div className={styles.productTitleContainer}>
            <div className={styles.productTitle}>Fi Series 3</div>
            <div className={styles.productTitleDescription}>Pick a color to get started</div>
          </div>
          <div className={styles.variantOptions}>
            <ColorOptions
              hasNonFiBandMaker={false}
              hasAnyUpchargedBands={false}
              selectedColorOption={selectedVariant.options.color}
              selectedVariant={selectedVariant}
              selectedBandVariantGroup={BandVariantGroup.FiOriginals}
              onVariantGroupSelect={() => {}}
              onSelect={(newColorOption: types.ColorOption) => {
                changeVariantOptions({ color: newColorOption });
              }}
              variants={getFilteredVariants(BandVariantGroup.FiOriginals, product.variants)}
              showTitle={false}
              selectedColorLabelPosition={'bottom'}
            />
          </div>
          <div ref={sizePickerRef}>
            <SizeOptions
              bandSeries={types.BandSeries.F3}
              selectedVariant={selectedVariant}
              onOptionChange={changeVariantOptions}
              title={'Pick your size'}
              variants={getFilteredVariants(BandVariantGroup.FiOriginals, product.variants)}
              startAsUnselected={true}
              showGiftFi={false}
            />
          </div>
          <CollarAddToBagButton
            disabled={!selectedSubscription}
            onAddToBag={onAddToBagClicked}
            ctaText={ctaButtonText(hasUpdatedSize)}
            fadeInRef={!hasUpdatedSize ? sizePickerRef : undefined}
            isVisibleOverride={hasUpdatedSize}
            descriptionText={hasUpdatedSize ? ctaDescriptionText(selectedVariant, selectedSubscription) : null}
          />
        </div>
      </div>
      <HideChatWidget />
      <ProductSummary />
      <FAQ />
    </>
  );
}
