import React from 'react';
import { Switch } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import { UpdateBillingInfoConfirmation } from './UpdateBillingInfoConfirmation';
import { UpdateBillingInfoCreditCard } from './UpdateBillingInfoCreditCard';

function UpdateBillingInfoRouter() {
  return (
    <Switch>
      <SentryRoute exact path={AppPaths.BillingInfoUpdate.Update} component={UpdateBillingInfoCreditCard} />
      <SentryRoute exact path={AppPaths.BillingInfoUpdate.Confirmation} component={UpdateBillingInfoConfirmation} />
    </Switch>
  );
}

export default function UpdateBillingInfo() {
  return <UpdateBillingInfoRouter />;
}
