import { gql } from '@apollo/client';

export const series3UpgradeOfferQuery = gql`
  query ECOMMERCE_series3UpgradeOffer($petId: ID) {
    series3UpgradeOffer(petId: $petId) {
      planCode
      deviceOffers {
        ...series3UpgradeDeviceOffer
      }
    }
  }

  fragment series3UpgradeDeviceOffer on Series3UpgradeDeviceOffer {
    proratedCreditAmountInCents
    promoDiscounts {
      discountAmountInCents
      planCode
    }
    device {
      moduleId
      series3UpgradeV2Eligible
    }
    pet {
      id
      name
      photos {
        first {
          image {
            fullSize
          }
        }
      }
    }
  }
`;
