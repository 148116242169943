import React, { ReactNode, useContext } from 'react';
import * as types from '../types';
import AppPaths, { SubscriptionCheckoutPaths, SubscriptionManageCheckoutPaths } from '../AppPaths';

interface BaseCartMode {
  allowPromoCodes: boolean;
  applePayErrorAlertWhenAccountExists: boolean;
  cartSelector: (state: types.AppState) => types.Cart;
  checkoutType: types.CheckoutType;
  checkoutContext?: types.PaymentContext;
  rootPath: string;
  showPayPal: boolean;
}

interface DefaultCartMode extends BaseCartMode {
  checkoutType: types.CheckoutType.Store;
}

interface UpgradeCartMode extends BaseCartMode {
  checkoutType: types.CheckoutType.Upgrade;
}

interface SubscriptionCartMode extends BaseCartMode {
  checkoutType:
    | types.CheckoutType.Subscription
    | types.CheckoutType.MembershipUpgrade
    | types.CheckoutType.MonthlyUpgrade;
  checkoutPaths: SubscriptionCheckoutPaths | SubscriptionManageCheckoutPaths;
}

type CartMode = DefaultCartMode | UpgradeCartMode | SubscriptionCartMode;

export class CartModes {
  static readonly default: DefaultCartMode = {
    allowPromoCodes: true,
    applePayErrorAlertWhenAccountExists: false,
    cartSelector: (state: types.AppState) => state.storeShop.cart,
    checkoutType: types.CheckoutType.Store,
    rootPath: AppPaths.Home,
    showPayPal: true,
  };

  static readonly series3: UpgradeCartMode = {
    allowPromoCodes: true,
    applePayErrorAlertWhenAccountExists: true,
    cartSelector: (state: types.AppState) => state.series3UpgradeShop.cart,
    checkoutType: types.CheckoutType.Upgrade,
    rootPath: AppPaths.Series3Upgrade.Root,
    showPayPal: false,
  };

  static readonly series3UpgradeV2: UpgradeCartMode = {
    allowPromoCodes: true,
    applePayErrorAlertWhenAccountExists: true,
    cartSelector: (state: types.AppState) => state.series3UpgradeShop.cart,
    checkoutType: types.CheckoutType.Upgrade,
    rootPath: AppPaths.Series3UpgradeV2.Root,
    showPayPal: false,
  };

  // Membership upgrade, purchase subscription, and manage subscription flows are
  // similar in that they're all meant to purchase a subscription for a specific device
  // so they can all share a cart model.
  static readonly purchaseSubscription: SubscriptionCartMode = {
    allowPromoCodes: true,
    applePayErrorAlertWhenAccountExists: true,
    cartSelector: (state: types.AppState) => state.subscriptionShop.cart,
    checkoutPaths: AppPaths.PurchaseSubscription,
    checkoutType: types.CheckoutType.Subscription,
    checkoutContext: types.PaymentContext.PURCHASE_SUBSCRIPTION,
    rootPath: AppPaths.PurchaseSubscription.Root,
    showPayPal: false,
  };

  static readonly membershipUpgrade: SubscriptionCartMode = {
    allowPromoCodes: false,
    applePayErrorAlertWhenAccountExists: true,
    cartSelector: (state: types.AppState) => state.subscriptionShop.cart,
    checkoutPaths: AppPaths.MembershipUpgrade,
    checkoutType: types.CheckoutType.MembershipUpgrade,
    checkoutContext: types.PaymentContext.MEMBERSHIP_UPGRADE,
    rootPath: AppPaths.MembershipUpgrade.Root,
    showPayPal: false,
  };

  static readonly monthlyUpgrade: SubscriptionCartMode = {
    allowPromoCodes: false,
    applePayErrorAlertWhenAccountExists: true,
    cartSelector: (state: types.AppState) => state.subscriptionShop.cart,
    checkoutPaths: AppPaths.MonthlyUpgrade,
    checkoutType: types.CheckoutType.MonthlyUpgrade,
    checkoutContext: types.PaymentContext.MONTHLY_UPGRADE,
    rootPath: AppPaths.MonthlyUpgrade.Root,
    showPayPal: false,
  };

  static readonly manageSubscription: SubscriptionCartMode = {
    allowPromoCodes: false,
    applePayErrorAlertWhenAccountExists: true,
    cartSelector: (state: types.AppState) => state.subscriptionShop.cart,
    checkoutPaths: AppPaths.Subscription,
    checkoutType: types.CheckoutType.MembershipUpgrade,
    checkoutContext: types.PaymentContext.MANAGE_SUBSCRIPTION,
    rootPath: AppPaths.Subscription.SplashPageForDevice(),
    showPayPal: true,
  };
}

const CartModeContext = React.createContext<CartMode>(CartModes.default);

export function CartModeProvider({ mode, children }: { mode: CartMode; children: ReactNode }) {
  return <CartModeContext.Provider value={mode}>{children}</CartModeContext.Provider>;
}

export function useCartMode(): CartMode {
  return useContext(CartModeContext);
}

export function useSubscriptionCartMode(): SubscriptionCartMode {
  const cartMode = useCartMode();
  if (
    cartMode.checkoutType !== types.CheckoutType.Subscription &&
    cartMode.checkoutType !== types.CheckoutType.MembershipUpgrade
  ) {
    throw new Error('useSubscriptionCartMode() can only be used with subscription cart modes');
  }

  return cartMode;
}
