import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { centsToDollars } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';
import * as types from '../../types';
import { Image } from '../Image';
import RemoveLineItemButton from '../RemoveLineItemButton';
import Dropdown from '../Dropdown';
import { cartActions } from '../../reducers/storeCart';
import { useDispatch } from 'react-redux';
import { ReactComponent as GiftIcon } from '../../assets/images/icons/gift.svg';

/**
 * Defines an individual pricing row to display for a cart item. In the case of bundles, we want to list out
 * all of the products in the bundle with a rolled up price. A cart item could have multiple pricing rows
 * e.g. we want to split out subscription activation fee price to display separately.
 */
export interface ItemPriceRowProps {
  discountedFromOriginalPriceInCents?: number;
  freeTrialLengthInDays?: number;
  productName: string;
  secondary?: boolean;
  totalPriceInCents?: number;
  variantDescription?: string;
}

interface ProductCartItemProps {
  id: types.CartItemID;
  sku: string;
  priceRows: ItemPriceRowProps[];
  productImageUrl?: string;
  removeCartItem?: () => void;
  updateGiftItem?: (isGift: boolean) => void;
  isGift?: boolean;
  quantity?: number;
}

function numberInCentsToMoney(numberInCents: number) {
  const isNegative = numberInCents < 0 ? '-' : '';
  return isNegative + '$' + centsToDollars(Math.abs(numberInCents));
}

function ItemPriceRow({
  discountedFromOriginalPriceInCents,
  freeTrialLengthInDays,
  productName,
  secondary,
  totalPriceInCents,
  variantDescription,
}: ItemPriceRowProps) {
  const discounted = !!discountedFromOriginalPriceInCents;
  const waived = totalPriceInCents === 0 && discounted;

  return (
    <div
      className={classNames(styles.lineItemPriceRow, {
        [styles.secondaryPriceRow]: !!secondary,
        [styles.waived]: waived,
      })}
    >
      <div className={styles.lineItemDetails}>
        <div className={styles.lineItemDetailsRow}>
          <div className={styles.productName}>
            {productName}
            {!secondary && variantDescription && (
              <span className={styles.variantDescription}> ({variantDescription})</span>
            )}
            {secondary && variantDescription && (
              <div className={styles.secondaryVariantDescription}>{variantDescription}</div>
            )}
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.lineItemPrices, {
          [styles.freeTrial]: !!freeTrialLengthInDays,
        })}
      >
        {!!discountedFromOriginalPriceInCents && (
          <div className={styles.lineItemOriginalPrice}>{numberInCentsToMoney(discountedFromOriginalPriceInCents)}</div>
        )}
        {!!totalPriceInCents && (
          <div
            className={classNames(styles.lineItemPrice, {
              [styles.credit]: totalPriceInCents < 0,
            })}
          >
            {numberInCentsToMoney(totalPriceInCents)}
          </div>
        )}
        {freeTrialLengthInDays && <div className={styles.freeTrialLength}>Free for {freeTrialLengthInDays} days</div>}
      </div>
    </div>
  );
}

export default function ProductCartItem({
  id,
  sku,
  priceRows,
  productImageUrl,
  removeCartItem,
  updateGiftItem,
  isGift,
  quantity,
}: ProductCartItemProps) {
  const dispatch = useDispatch();
  const [currentQuantity, setCurrentQuantity] = useState(quantity);
  const quantityOptions = useMemo(() => {
    const list = [];
    for (let i = 1; i <= 20; i++) {
      list.push({ value: i, label: i.toString() });
    }
    return list;
  }, []);
  const selectedQuantity = useMemo(() => {
    if (!currentQuantity) {
      return;
    }

    return quantityOptions.find(({ value }) => value === currentQuantity);
  }, [quantityOptions, currentQuantity]);

  return (
    <div className={styles.lineItem}>
      {removeCartItem && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton onClick={removeCartItem} />
        </div>
      )}
      <div className={styles.itemRow}>
        <div
          className={classNames(styles.lineItemImage, {
            [styles.empty]: !productImageUrl,
          })}
        >
          {productImageUrl && <Image image={{ type: 'image', url: productImageUrl }} alt={priceRows[0].productName} />}
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            {priceRows.map((priceRow, i) => (
              <ItemPriceRow
                discountedFromOriginalPriceInCents={priceRow.discountedFromOriginalPriceInCents}
                freeTrialLengthInDays={priceRow.freeTrialLengthInDays}
                key={i}
                productName={priceRow.productName}
                secondary={priceRow.secondary}
                totalPriceInCents={priceRow.totalPriceInCents}
                variantDescription={priceRow.variantDescription}
              />
            ))}
          </div>
          {updateGiftItem && !isGift && (
            <div className={styles.addGiftPurchase} onClick={() => updateGiftItem(true)}>
              <GiftIcon /> Buying as a Gift?
            </div>
          )}
          {isGift && (
            <div
              className={classNames(styles.removeGiftPurchase, {
                [styles.removeGiftPurchaseDisabled]: !updateGiftItem,
              })}
              onClick={() => updateGiftItem && updateGiftItem(false)}
            >
              <GiftIcon /> Gift wrap included!
            </div>
          )}
          {sku !== 'F1-N-20' && quantity && quantity > 1 ? (
            <div className={styles.lineItemQuantity}>x {quantity}</div>
          ) : null}
          {sku === 'F1-N-20' ? (
            <div className={styles.lineItemQuantity}>
              <span className={styles.quantityTitle}>Quantity</span>
              <div className={classNames('cart-quantity-container', styles.quantityContainer)}>
                <Dropdown
                  id="cartQuantity"
                  options={quantityOptions}
                  selectedOption={selectedQuantity}
                  onChange={(option) => {
                    if (option) {
                      setCurrentQuantity(option.value);
                      dispatch(cartActions.updateCartItemQuantity({ id, quantity: option.value }));
                    }
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
